import React, { useState, useEffect } from "react";
import styled from "styled-components";

//components
import PageHeading from "../components/PageHeading";
import AnimatedComponent from "../components/AnimatedComponent";

//router
import { useParams } from "react-router-dom";

//icons
import { Plus } from "@styled-icons/bootstrap/Plus";
import { Minus } from "@styled-icons/boxicons-regular/Minus";

//redux
import { useSelector } from "react-redux";
import { selectLanguage } from "../redux/variables";

//additionals
import { Helmet } from "react-helmet";

const Services = () => {
  //router
  const params = useParams();

  //redux
  const reduxLang = useSelector(selectLanguage);

  const [pageData, setPageData] = useState({});

  const pageDataLatvian = {
    name: "Mūsu piedāvājums",
    desc: "Iepazīsties ar servisiem, ar kuriem strādājām ikdienā.",
    img: "../img/pages/services.jpg",
  };

  const pageDataEnglish = {
    name: "Our Services",
    desc: "Explore the services we work with daily.",
    img: "../img/pages/services.jpg",
  };

  const [data, setData] = useState([]);

  const dataLatvian = [
    {
      id: 0,
      selector: "web",
      name: "Mājas lapu izstrāde",
      desc_top:
        'Mūsu "Mājaslapas Izstrādes" pakalpojums ļauj jums realizēt savus digitālos mērķus, izveidojot un pielāgojot mājaslapu tieši pēc jūsu vajadzībām. Mēs saprotam, ka ikvienam klientam vajadzības ir unikālas, tāpēc mūsu komanda strādās ar jums, lai izveidotu mājaslapu, kas atspoguļo jūsu zīmolu un piedāvājumu.',
      desc_bottom:
        "Mūsu mērķis ir nodrošināt jums mājaslapu, kas ne tikai izceļ jūsu uzņēmumu, bet arī palīdz jums sasniegt savus digitālos mērķus. Ar mums jūs varat būt droši, ka jūsu mājaslapa būs saderīga ar mūsdienīgajām prasībām un atbilstoša jūsu uzņēmuma vērtībām.",
      reasons: [
        {
          id: 0,
          name: "Personalizēts Dizains.",
          text: "Paredzēts specifiskai auditorijai vai lietotājam, ņemot vērā viņu vajadzības, intereses. Tas ietver individuālu pieeju, kurā tiek pielāgota funkcionalitāte, lai atbilstu konkrētiem klienta vēlmēm un prasībām.",
        },
        {
          id: 1,
          name: "Responsīvs dizains.",
          text: "Mēs izstrādājam mājaslapas, ņemot vērā mobilās pirmās pieejas principus. Šodien mobilo ierīču izmantošana ir nenoliedzams fakts, un mūsu mājaslapas būs pilnībā responsīvas - tās izskatīsies lieliski un būs viegli lietojamas gan datoros, gan mobilo telefonu ekrānos.",
        },
        {
          id: 2,
          name: "Pilnīgu Pielāgojamību.",
          text: "Jūs varat paļauties uz mums, lai ieviestu funkcionalitāti, ko jūs vēlaties. Vai tas ir iepirkšanās veikals, bloga daļa, rezervācijas sistēma vai kas cits, mēs nodrošināsim, lai jūsu mājaslapa būtu pilnībā pielāgojama.",
        },
        {
          id: 3,
          name: "SEO Optimizācija.",
          text: "Mūsu pakalpojumi ietver arī SEO optimizāciju, lai jūsu mājaslapa būtu viegli atrodama meklētājos. Mēs veicam atslēgvārdu analīzi, pielāgojam satura struktūru un veicam tehnikas uzlabojumus, lai palielinātu jūsu mājaslapas redzamību meklētājrezultātos.",
        },
      ],
      price: 149,
      price_tag: "Cena sākot no",
      currency: "eiro",
      isOpened: false,
    },
    {
      id: 1,
      selector: "ecommerce",
      name: "Internetveikalu izstrāde",
      desc_top:
        'Mūsu "Internetveikala Izstrādes" pakalpojums ļauj jums ienirt e-komercijas pasaulē ar spēcīgu un funkcionālu interneta veikalu. Mēs pievēršamies tam, lai jūsu veikals ne tikai izskatītos pievilcīgi, bet arī piedāvātu lietotājiem gludu un ērtu pirkumu pieredzi.',
      desc_bottom:
        'Ar mūsu "Internetveikala Izstrādes" pakalpojumu jūs varēsiet ērti uzsākt un vadīt savu e-komercijas biznesu, nemazinoties ar tehniskiem aspektiem. Mēs uzņemsimies gandrīz visu smagāko darbu, lai jūs varētu koncentrēties uz pārdošanu un jūsu uzņēmuma attīstību. Mūsu mērķis ir nodrošināt, ka jūsu interneta veikals ir pievilcīgs, efektīvs un veicina jūsu biznesa izaugsmi.',
      reasons: [
        {
          id: 0,
          name: "Pilnvērtīga E-komercijas Platforma.",
          text: "Mēs izveidosim jums interneta veikalu, kas nodrošinās pilnu produktu pārvaldību, pasūtījumu apstrādi un maksājumu risinājumus. Jūs varēsiet ērti pārvaldīt produktu katalogu un veikt izmaiņas pēc nepieciešamības.",
        },
        {
          id: 1,
          name: "Responsīvs Dizains.",
          text: "Saprotam, cik svarīga ir mobilo ierīču lietošana, tāpēc mūsu interneta veikali ir pilnībā responsīvi. Tas nozīmē, ka jūsu klienti varēs iepirkties un veikt pirkumus ne tikai no datoriem, bet arī no planšetēm un mobilo telefonu ekrāniem.",
        },
        {
          id: 2,
          name: "Droši Maksājuma Risinājumi.",
          text: "Mēs integrēsim drošus maksājumu risinājumus, lai nodrošinātu, ka jūsu klienti var veikt maksājumus droši un ērti. Tas palielinās klientu uzticēšanos un veicinās pirkumu noslēgšanu.",
        },
        {
          id: 3,
          name: "Personalizētas Lietotāja Pieredzes.",
          text: "Jūsu interneta veikals tiks pielāgots, lai atbilstu jūsu mērķauditorijas vēlmēm un vajadzībām. Piedāvāsim pielāgojamus filtrus, lietotāja kontus un ieteikumu sistēmu, lai nodrošinātu personalizētu lietotāja pieredzi.",
        },
        {
          id: 4,
          name: "SEO Optimizācija.",
          text: "Mūsu interneta veikala izstrāde ietver arī SEO optimizāciju, kas palielinās jūsu veikala redzamību meklētājrezultātos. Tas palīdzēs piesaistīt vairāk potenciālo klientu un veicināt pārdošanas augšanu.",
        },
        {
          id: 5,
          name: "Mārketinga Integrācija.",
          text: "Integrēsim sociālo mediju un digitālo mārketinga rīkus, lai palīdzētu jums sasniegt lielāku auditoriju un piesaistīt klientus caur dažādām kanāliem.",
        },
      ],
      price: 699,
      price_tag: "Cena sākot no",
      currency: "eiro",
      isOpened: false,
    },
    {
      id: 2,
      selector: "seo",
      name: "SEO optimizācija",
      desc_top:
        "Mūsu “SEO Optimizācijas” pakalpojums ir domāts tam, lai uzlabotu jūsu mājaslapas redzamību meklētājrezultātos, padarot to viegli atrodamu un ieinteresētu jūsu mērķauditorijai. Mēs saprotam, cik svarīgi ir būt pirmajās rindās, kad cilvēki veic meklēšanu, tāpēc mūsu komanda izdarīs tā, lai jūs būtu pirmajā vietā! ",
      desc_bottom:
        "Mūsu “SEO Optimizācija” pakalpojums sniedz jums iespēju iegūt organiskas redzamības priekšrocības, piesaistot vairāk kvalitatīvu apmeklētāju un potenciālo klientu. Ar mums jūs varēsiet palielināt savu klātbūtni tiešsaistē un sasniegt lielāku auditoriju, kas meklē tieši to, ko jūs piedāvājat.",
      reasons: [
        {
          id: 0,
          name: "Atslēgvārdu Analīze.",
          text: "Mēs identificēsim atslēgvārdus, kas ir svarīgi jūsu nozarē un potenciālajiem klientiem. Tas palīdzēs jums parādīties tieši tiem cilvēkiem, kuri meklē tieši to, ko jūs piedāvājat.",
        },
        {
          id: 1,
          name: "Satura Optimizācija.",
          text: "Mēs pielāgosim jūsu mājaslapas saturu, lai tas būtu pielāgots atslēgvārdiem un nodrošinātu informāciju, ko cilvēki meklē. Tā rezultātā jūsu mājaslapa varēs augt meklētājrezultātos un uzlabot tās redzamību.",
        },
        {
          id: 2,
          name: "Tehniskie Uzlabojumi.",
          text: "Mēs veiksim tehniskus uzlabojumus, lai nodrošinātu, ka jūsu mājaslapa ir viegli indeksējama meklētājdzinisem. Tas ietver tādu aspektu kā lapas ātrdarbība, mobilo saderība un pareiza kodējuma struktūra.",
        },
      ],
      price: 49,
      price_tag: "Cena sākot no",
      currency: "eiro",
      isOpened: false,
    },
    {
      id: 3,
      selector: "design",
      name: "Grafiskais dizains",
      desc_top:
        'Mūsu "Grafiskais Dizains" pakalpojums ir visaptveroša pieeja jūsu vizuālajai tēlam. Ja jums nepieciešama mājaslapas dizaina izveidošana vai uzlabošana, mēs esam šeit, lai palīdzētu iegūt Jums vēlamo rezultātu!',
      desc_bottom:
        "Mūsu mērķis ir dot jums radošu atbalstu, padarot jūsu vizuālo identitāti piesaistošu un atmiņā paliekošu. Neatkarīgi no tā, vai jums nepieciešams atsvaidzināt esošo dizainu, vai arī jūs meklējat jaunas idejas, mēs esam šeit, lai palīdzētu jums izcelties un piesaistīt jūsu mērķauditoriju.",
      reasons: [
        {
          id: 0,
          name: "Mājaslapas Dizaina Papildinājums.",
          text: "Mēs palīdzēsim jums ar vizuālo pieeju, papildinot jūsu mājaslapas dizainu ar uzkrītošiem elementiem, kas uzlabos lietotāja pieredzi.",
        },
        {
          id: 1,
          name: "Banneru, Attēlu Un Logotipu Izstrāde.",
          text: "Mēs veidojam uzmanību piesaistošus banerus, pievilcīgus attēlus un atpazīstamus logotipus, kas atspoguļos jūsu zīmolu un vēstījumu.",
        },
        {
          id: 2,
          name: "Konsultācija Un Ideju Sniegšana.",
          text: "Ja jums ir idejas, bet vēlaties profesionālu grafisko dizainu, mēs piedāvājam konsultācijas, lai palīdzētu jums sasniegt vislabāko dizaina risinājumu.",
        },
      ],
      price: 99,
      price_tag: "Cena sākot no",
      currency: "eiro",
      isOpened: false,
    },
    {
      id: 4,
      selector: "cms",
      name: "Satura vadības sistema",
      desc_top:
        'Mūsu "Satura Vadības Sistēmas (CMS)" pakalpojums sniedz jums pilnu kontroli pār jūsu mājaslapas saturu un piedāvā daudz vairāk par vienkāršu teksta rediģēšanu. Tas ir jūsu digitālais administratora panelis, kas ne tikai ļauj viegli atjaunināt saturu, bet arī nodrošina praktiskas funkcijas, kas uzlabo jūsu mājaslapas funkcionalitāti.',
      desc_bottom:
        "Mūsu “Satura Vadības Sistēma (CMS)” pakalpojums ir jūsu digitālais administratora panelis, kas ne tikai palīdz uzturēt mājaslapas saturu, bet arī piedāvā praktiskas un dinamiskas funkcijas, kas padara jūsu ikdienu efektīvāku. Jums nav jābūt tehniskam ekspertam, lai izmantotu šo spēcīgo rīku jūsu uzņēmuma labā.",
      reasons: [
        {
          id: 0,
          name: "Ērtu Satura Pārvaldība.",
          text: "Ar viegli lietojamu satura vadības sistēmu jūs varat viegli pievienot jaunumus, bildes, video un citu saturu savai mājaslapai. Tas ļauj jums ātri atjaunināt informāciju un dalīties ar svarīgiem ziņojumiem.",
        },
        {
          id: 1,
          name: "Dinamiskas Funkcijas.",
          text: "Mēs pievienosim jebkādas papildu funkcijas, kas atbilst jūsu prasībām, piemēram, iespēju ģenerēt rēķinus, pārskatus, kopsavilkumus PDF vai CSV formātā.",
        },
        {
          id: 2,
          name: "Lietotājam Draudzīgs Interfeiss.",
          text: "Jūs nekad nejutīsiet, ka jums ir jābūt programmētājam, lai rediģētu mājaslapu. Mūsu CMS nodrošina draudzīgu un intuitīvu interfeisu, kas ļauj jums veikt izmaiņas.",
        },
        {
          id: 3,
          name: "Pielāgojams Dizains.",
          text: "Mēs varam pielāgot CMS dizainu, lai tas atbilstu jūsu pašreizējai mājaslapai vai, lai tas sakristu ar jūsu vēlmēm.",
        },
        {
          id: 4,
          name: "Drošība Un Atbalsts.",
          text: "Mēs nodrošinām, ka jūsu CMS ir drošs un aizsargāts, un mēs esam gatavi palīdzēt jums ar jebkādiem jautājumiem vai problēmām, kas rodas.",
        },
      ],
      price: 299,
      price_tag: "Cena sākot no",
      currency: "eiro",
      isOpened: false,
    },
    {
      id: 5,
      selector: "mobile",
      name: "Mobilo aplikāciju izveide",
      desc_top:
        "Viedtelefoniem un planšetdatoriem. Tāpat kā web aplikācijas, mobilās aplikācijas nodrošina lietotājiem dažādus pakalpojumus un funkcijas, taču tās ir izstrādātas, ņemot vērā mobilās ierīces specifiku un ierobežojumus.",
      price: 399,
      price_tag: "Cena sākot no",
      currency: "eiro",
      isOpened: false,
    },
    {
      id: 6,
      selector: "web-systems",
      name: "Personalizētas WEB sistēmas",
      desc_top:
        "Mūsu “Personalizēto WEB Sistēmu” pakalpojums ļauj jums izveidot un pielāgot unikālas web sistēmas, kas tieši atbilst jūsu uzņēmuma un darbības vajadzībām. Mēs specializējamies radīt dažādus risinājumus, piemēram, atskaišu sistēmas, rezervācijas sistēmas, grāmatvedības instrumentus un daudz ko citu.",
      desc_bottom:
        "Mūsu “Personalizēto WEB Sistēmu” pakalpojums ir jūsu iespēja izveidot un pielāgot web risinājumu, kas tieši atbilst jūsu biznesa vajadzībām. Mēs nodrošinām, ka jūsu sistēma ir unikāla, efektīva un sniedz jums pārskatus, vadības instrumentus vai citas funkcijas, kas palīdzēs jums uzlabot darbību un efektivitāti.",
      reasons: [
        {
          id: 0,
          name: "Unikāla Sistēmas Izstrāde.",
          text: "Neatkarīgi no jūsu uzņēmuma specifiskajām prasībām, mēs izstrādāsim personalizētu web sistēmu, kas paātrinās jūsu darba procesus un citas īpašās vajadzības.",
        },
        {
          id: 1,
          name: "Pielāgojama Funkcionalitāte.",
          text: "Mēs pievienosim nepieciešamos funkciju blokus, piemēram, atskaišu ģenerēšanu, rezervāciju vadību vai grāmatvedības funkcijas, lai nodrošinātu, ka jūsu sistēma pilnībā atbilst jūsu darbības prasībām.",
        },
        {
          id: 2,
          name: "Lietotājam Draudzīgs Interfeiss.",
          text: "Mēs uzmanīgi izstrādāsim lietotājiem draudzīgu interfeisu, kas padarīs jūsu sistēmas lietošanu ērtu un efektīvu.",
        },
        {
          id: 3,
          name: "Drošība Un Atbalsts.",
          text: "Mēs nodrošinām, ka jūsu personalizētā WEB sistēma ir droša un darbojas nepārtraukti, piedāvājot tehnisko atbalstu un pielāgojumus pēc nepieciešamības.",
        },
      ],
      price: 499,
      price_tag: "Cena sākot no",
      currency: "eiro",
      isOpened: false,
    },
  ];

  const dataEnglish = [
    {
      id: 0,
      selector: "web",
      name: "Website Development",
      desc_top:
        'Our "Website Development" service allows you to achieve your digital goals by creating and customizing a website according to your needs. We understand that every client has unique requirements, so our team will work with you to create a website that reflects your brand and offerings.',
      desc_bottom:
        "Our goal is to provide you with a website that not only highlights your business but also helps you achieve your digital objectives. With us, you can be confident that your website will be in line with modern standards and aligned with your company values.",
      reasons: [
        {
          id: 0,
          name: "Personalized Design.",
          text: "Tailored to a specific audience or user, taking into account their needs and interests. This involves an individual approach, adapting functionality to meet specific client preferences and requirements.",
        },
        {
          id: 1,
          name: "Responsive Design.",
          text: "We develop websites considering the principles of mobile-first design. Today, the use of mobile devices is undeniable, and our websites will be fully responsive, looking great and being user-friendly on both desktop and mobile screens.",
        },
        {
          id: 2,
          name: "Complete Customizability.",
          text: "You can rely on us to implement the functionality you want. Whether it's an online store, a blog section, a reservation system, or anything else, we will ensure your website is fully customizable.",
        },
        {
          id: 3,
          name: "SEO Optimization.",
          text: "Our services also include SEO optimization to make your website easily discoverable in search engines. We perform keyword analysis, adjust content structure, and make technical improvements to enhance your website's visibility in search results.",
        },
      ],
      price: 149,
      price_tag: "Price starting from",
      currency: "euros",
      isOpened: false,
    },
    {
      id: 1,
      selector: "ecommerce",
      name: "E-commerce Development",
      desc_top:
        'Our "E-commerce Development" service allows you to enter the world of e-commerce with a powerful and functional online store. We focus on making your store not only visually appealing but also providing users with a smooth and convenient shopping experience.',
      desc_bottom:
        'With our "E-commerce Development" service, you can start and manage your e-commerce business with ease, without getting bogged down by technical aspects. We will handle most of the heavy lifting, allowing you to focus on sales and growing your business. Our goal is to ensure that your online store is attractive, efficient, and contributes to your business growth.',
      reasons: [
        {
          id: 0,
          name: "Comprehensive E-commerce Platform.",
          text: "We will create an online store that provides full product management, order processing, and payment solutions. You'll be able to easily manage your product catalog and make changes as needed.",
        },
        {
          id: 1,
          name: "Responsive Design.",
          text: "We understand the importance of mobile device usage, so our online stores are fully responsive. This means your customers can shop and make purchases not only from desktops but also from tablets and mobile phone screens.",
        },
        {
          id: 2,
          name: "Secure Payment Solutions.",
          text: "We will integrate secure payment solutions to ensure that your customers can make payments safely and conveniently. This will increase customer trust and facilitate successful transactions.",
        },
        {
          id: 3,
          name: "Personalized User Experience.",
          text: "Your online store will be tailored to match the preferences and needs of your target audience. We'll provide customizable filters, user accounts, and recommendation systems to ensure a personalized user experience.",
        },
        {
          id: 4,
          name: "SEO Optimization.",
          text: "Our e-commerce development also includes SEO optimization, which will increase your store's visibility in search results. This will help attract more potential customers and boost sales growth.",
        },
        {
          id: 5,
          name: "Marketing Integration.",
          text: "We will integrate social media and digital marketing tools to help you reach a wider audience and attract customers through various channels.",
        },
      ],
      price: 699,
      price_tag: "Price starting from",
      currency: "euros",
      isOpened: false,
    },
    {
      id: 2,
      selector: "seo",
      name: "SEO Optimization",
      desc_top:
        "Our \"SEO Optimization\" service is designed to improve your website's visibility in search results, making it easily discoverable and engaging to your target audience. We understand the importance of being in the top ranks when people search, so our team will work to ensure you're at the forefront!",
      desc_bottom:
        'Our "SEO Optimization" service provides you with the opportunity to gain the benefits of organic visibility, attracting more quality visitors and potential customers. With us, you can enhance your online presence and reach a larger audience searching for exactly what you offer.',
      reasons: [
        {
          id: 0,
          name: "Keyword Analysis.",
          text: "We will identify keywords that are important in your industry and to potential customers. This will help you appear to the people who are searching for what you offer.",
        },
        {
          id: 1,
          name: "Content Optimization.",
          text: "We will tailor your website content to be optimized for keywords and provide information that people are looking for. As a result, your website can climb the search results and improve its visibility.",
        },
        {
          id: 2,
          name: "Technical Improvements.",
          text: "We will make technical improvements to ensure that your website is easily indexed by search engines. This includes aspects such as page speed, mobile compatibility, and proper code structure.",
        },
      ],
      price: 49,
      price_tag: "Price starting from",
      currency: "euros",
      isOpened: false,
    },
    {
      id: 3,
      selector: "design",
      name: "Graphic Design",
      desc_top:
        'Our "Graphic Design" service is a comprehensive approach to your visual identity. Whether you need website design creation or enhancement, we\'re here to help you achieve the desired outcome!',
      desc_bottom:
        "Our goal is to provide you with creative support, making your visual identity captivating and memorable. Whether you need to refresh an existing design or seek new ideas, we're here to help you stand out and appeal to your target audience.",
      reasons: [
        {
          id: 0,
          name: "Website Design Enhancement.",
          text: "We'll help you with a visual approach, adding striking elements to your website design that enhance the user experience.",
        },
        {
          id: 1,
          name: "Banner, Image, and Logo Creation.",
          text: "We create attention-grabbing banners, appealing images, and recognizable logos that reflect your brand and message.",
        },
        {
          id: 2,
          name: "Consultation and Idea Generation.",
          text: "If you have ideas but need professional graphic design, we offer consultations to help you achieve the visual identity you're aiming for.",
        },
      ],
      price: 99,
      price_tag: "Price starting from",
      currency: "euros",
      isOpened: false,
    },
    {
      id: 4,
      selector: "cms",
      name: "Content Management System",
      desc_top:
        'Our "Content Management System (CMS)" service gives you full control over your website content and offers much more than simple text editing. It serves as your digital admin panel, not only making content updates easy but also providing practical features to enhance your website\'s functionality.',
      desc_bottom:
        "Our \"Content Management System (CMS)\" service is your digital admin panel that helps you maintain your website's content and provides practical and dynamic features to make your daily tasks more efficient. You don't need to be a technical expert to use this powerful tool for the benefit of your business.",
      reasons: [
        {
          id: 0,
          name: "Easy Content Management.",
          text: "With an intuitive content management system, you can easily add news, images, videos, and other content to your website. This allows you to quickly update information and share important announcements.",
        },
        {
          id: 1,
          name: "Dynamic Features.",
          text: "We'll add any necessary additional features that match your needs, such as generating invoices, reports, summaries in PDF or CSV format.",
        },
        {
          id: 2,
          name: "User-Friendly Interface.",
          text: "You'll never feel like you need to be a programmer to edit your website. Our CMS provides a friendly and intuitive interface that allows you to make changes easily.",
        },
        {
          id: 3,
          name: "Customizable Design.",
          text: "We can customize the CMS design to match your current website or align with your preferences.",
        },
        {
          id: 4,
          name: "Security and Support.",
          text: "We ensure that your CMS is secure and protected, and we're ready to assist you with any questions or issues that arise.",
        },
      ],
      price: 299,
      price_tag: "Price starting from",
      currency: "euros",
      isOpened: false,
    },
    {
      id: 5,
      selector: "mobile",
      name: "Mobile App Development",
      desc_top:
        "For smartphones and tablets. Just like web applications, mobile apps offer users various services and features, but they are designed with the specifics and limitations of mobile devices in mind.",
      price: 399,
      price_tag: "Price starting from",
      currency: "euros",
      isOpened: false,
    },
    {
      id: 6,
      selector: "web-systems",
      name: "Custom WEB Systems",
      desc_top:
        "Our \"Custom WEB Systems\" service allows you to create and customize unique web systems that directly match your company's and operation's needs. We specialize in creating various solutions, such as reporting systems, reservation systems, accounting tools, and much more.",
      desc_bottom:
        'Our "Custom WEB Systems" service gives you the opportunity to create and customize a web solution that directly meets your business needs. We ensure that your system is unique, efficient, and provides you with insights, management tools, or other features to help you improve operations and efficiency.',
      reasons: [
        {
          id: 0,
          name: "Unique System Development.",
          text: "Regardless of your company's specific requirements, we'll develop a personalized web system that accelerates your workflow and addresses unique needs.",
        },
        {
          id: 1,
          name: "Customizable Functionality.",
          text: "We'll add the necessary function blocks, such as report generation, reservation management, or accounting functions, to ensure your system fully meets your operational requirements.",
        },
        {
          id: 2,
          name: "User-Friendly Interface.",
          text: "We'll carefully design a user-friendly interface that makes using your system easy and effective.",
        },
        {
          id: 3,
          name: "Security and Support.",
          text: "We ensure that your custom WEB system is secure and operational around the clock, offering technical support and adjustments as needed.",
        },
      ],
      price: 499,
      price_tag: "Price starting from",
      currency: "euros",
      isOpened: false,
    },
  ];

  useEffect(() => {
    if (reduxLang) {
      if (reduxLang === "lv") {
        setPageData(pageDataLatvian);
        setData(dataLatvian);
        setAccordion(dataLatvian);
      } else if (reduxLang === "eng") {
        setPageData(pageDataEnglish);
        setData(dataEnglish);
        setAccordion(dataEnglish);
      }
    }
    //eslint-disable-next-line
  }, [reduxLang]);

  const [accordion, setAccordion] = useState([]);

  const handleAccordionNav = (id) => {
    const updatedAccordionItems = accordion.map((item) => {
      if (item.selector === id) {
        item.isOpened = !item.isOpened;
      }
      return item;
    });

    setAccordion(updatedAccordionItems);
  };

  useEffect(() => {
    const { id } = params;

    if (data?.length > 0) {
      setAccordion(data);

      if (id) {
        const accordionIndex = id;

        if (accordionIndex !== null) {
          handleAccordionNav(accordionIndex);

          setTimeout(() => {
            const selectedService = document.querySelector(`#${id}`);

            if (selectedService) {
              selectedService.scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "nearest",
              });
            }
          }, 300);
        }
      }
    }
    //eslint-disable-next-line
  }, [data, params]);

  const handleAccordionClick = (id) => {
    const updatedAccordionItems = accordion.map((item) => {
      if (item.id === id) {
        item.isOpened = !item.isOpened;
      }
      return item;
    });

    setAccordion(updatedAccordionItems);
  };

  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>JAAIGROUP: Mūsu Servisi</title>
        <link rel="canonical" href="http://jaaigroup.com/" />
        <meta
          property="og:title"
          content="JAAIGROUP: Mājaslapu Izstrāde un Apkalpošana"
        />
        <meta name="author" content="JAAIGROUP" />
        <meta
          name="description"
          content="Izpētiet mūsu plašo servisu klāstu, lai veidotu un uzlabotu savu digitālo klātbūtni. Mēs piedāvājam visaptverošus risinājumus, ieskaitot mājas lapu dizainu un izstrādi, hostingu un uzturēšanu, SEO optimizāciju, e-komercijas risinājumus un sociālo mediju integrāciju. Neatkarīgi no jūsu vajadzībām, mēs strādājam, lai palīdzētu sasniegt jūsu biznesa mērķus."
        />
        <meta
          property="og:description"
          content="Izpētiet mūsu plašo servisu klāstu, lai veidotu un uzlabotu savu digitālo klātbūtni. Mēs piedāvājam visaptverošus risinājumus, ieskaitot mājas lapu dizainu un izstrādi, hostingu un uzturēšanu, SEO optimizāciju, e-komercijas risinājumus un sociālo mediju integrāciju. Neatkarīgi no jūsu vajadzībām, mēs strādājam, lai palīdzētu sasniegt jūsu biznesa mērķus."
        />
        <meta property="og:locale" content="lv_LV" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="http://jaaigroup.com/" />
        <meta
          property="og:site_name"
          content="JAAIGROUP: Mājaslapu Izstrāde un Apkalpošana"
        />
        <meta
          name="twitter:description"
          content="Mājas lapu izstrāde jūsu biznesam vai organizācijai. Mēs radām digitālus risinājumus, kas realizē jūsu redzējumu dzīvē."
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content="JAAIGROUP: Mājaslapu Izstrāde un Apkalpošana"
        />
        <meta
          name="keywords"
          content="Mājaslapu izstrāde, mājaslapu dizains, mājaslapu hostings, SEO optimizācija, e-komercijas risinājumi, sociālo mediju integrācija, digitālais mārketings, mājaslapu pakalpojumi, uzņēmumu mājaslapas, organizāciju mājaslapas, individuālie projekti, web dizains, interfeisa izstrāde, tīmekļa izstrāde, mobilais dizains, responsīvs dizains, lietotāja pieredze, lietotāja interfeiss, klientu piesaiste, uzņēmējdarbības attīstība, tiešsaistes klātbūtne, mārketinga stratēģija"
        />
      </Helmet>

      <AnimatedComponent>
        <PageHeading data={pageData} />

        <div className="list-item-container">
          {accordion?.map((item) => (
            <div key={item?.id} className="list-item">
              <div className="list-item-heading">
                <h3>{item?.name}</h3>

                <div
                  onClick={() => handleAccordionClick(item?.id)}
                  className="list-item-toggle"
                  style={{
                    backgroundColor: item?.isOpened ? "#376058" : "white",
                  }}
                >
                  {item?.isOpened ? (
                    <Minus className="icon" style={{ color: "white" }} />
                  ) : (
                    <Plus className="icon" />
                  )}
                </div>
              </div>

              {item?.isOpened && (
                <div className="list-item-data" id={item?.selector}>
                  <p>{item?.desc_top}</p>

                  <div className="list-item-reasons">
                    {item?.reasons &&
                      item?.reasons?.map((reason) => (
                        <div key={reason?.id} className="list-item-reason">
                          <p>
                            <span style={{ fontWeight: "bold" }}>
                              {reason?.name}
                            </span>{" "}
                            {reason?.text}
                          </p>
                        </div>
                      ))}
                  </div>

                  {item?.desc_bottom && <p>{item?.desc_bottom}</p>}
                  <p
                    style={{
                      fontWeight: "bold",
                      marginBottom: 0,
                      marginTop: 40,
                    }}
                  >
                    {item?.price_tag}{" "}
                    <span style={{ color: "#376058" }}>{item?.price},-</span>{" "}
                    {item?.currency}.
                  </p>
                </div>
              )}
            </div>
          ))}
        </div>
      </AnimatedComponent>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .list-item-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 80px 0 20px;

    @media only screen and (max-width: 766px) {
      padding: 0;
    }

    .list-item {
      margin: 0 0 30px 0;
      padding: 0 20px 30px;
      border-bottom: 2px solid #dedede;

      .list-item-data {
        max-width: 90%;
        margin-top: 20px;

        p {
          font-size: 0.9rem;
          color: #121315;
          margin: 0 0 10px 0;
          line-height: 1.4;
        }
      }

      .list-item-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h3 {
          font-size: 1.4rem;
          color: #121315;
        }

        .list-item-toggle {
          border: 1px solid #376058;
          border-radius: 50%;
          padding: 6px;
          cursor: pointer;
          transition: 0.2s ease-in-out;

          .icon {
            width: 24px;
            height: 24px;
            color: #376058;
          }
        }

        .list-item-toggle:hover {
          background: #376058 !important;

          .icon {
            color: white;
          }
        }
      }
    }
  }
`;

export default Services;
