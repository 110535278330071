import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

//router
import { Link } from "react-router-dom";

//icons
import { ArrowUpRight } from "@styled-icons/bootstrap/ArrowUpRight";
import { ArrowLeft } from "@styled-icons/bootstrap/ArrowLeft";
import { ArrowRight } from "@styled-icons/bootstrap/ArrowRight";
import { ArrowIosBackOutline } from "@styled-icons/evaicons-outline/ArrowIosBackOutline";
import { ArrowIosForwardOutline } from "@styled-icons/evaicons-outline/ArrowIosForwardOutline";

//components
import OfferBanner from "../components/OfferBanner";
import AnimatedComponent from "../components/AnimatedComponent";

//redux
import { useSelector } from "react-redux";
import { selectLanguage } from "../redux/variables";

const Home = () => {
  //redux
  const reduxLang = useSelector(selectLanguage);

  //data

  const dataLatvian = [
    {
      id: 0,
      name: "welcome-banner",
      text: [
        {
          id: 0,
          text: "🤝 Mājaslapu izstrāde jūsu biznesam vai organizācijai.",
        },
        {
          id: 1,
          text: "Mūsu mājaslapas izstrādes pakalpojumi ir pieejami visiem budžetiem, tāpēc varat sākt šodien.",
        },
        {
          id: 2,
          text: "Mēs piedāvājam pilnu web pakalpojumu klāstu, sākot no vizītkaršu, landing lapu izstrādi līdz sarežģītām satura vadības sistēmām. Personalizēts dizains. Web un e-pasta hostinga iespējas, bez uztraukuma, kur glabāt savu mājaslapu.",
        },
        { id: 3, text: "Uzzināt vairāk" },
      ],
    },
    {
      id: 1,
      name: "consultation-banner",
      text: [
        {
          id: 0,
          text: "Mūsdienu digitālajā laikmetā mājaslapas vai tīmekļa lietotņu klātbūtne var būt būtiska uzņēmumiem un privātpersonām, kas vēlas prezentēt sevi vai savu biznesu interneta vidē.",
        },
        {
          id: 1,
          text: "Mēs piedāvājam bezmaksas konsultāciju, kurā mēs varam palīdzēt jums izvēlēties sākot no platformas izvēles līdz jūsu mājaslapas projektēšanai, izveidei un publicēšanai. Kā arī informācija par izmaksām.",
        },
        { id: 2, text: "Saņemt konsultāciju" },
      ],
    },
    {
      id: 2,
      name: "services-banner",
      text: [
        {
          id: 0,
          text: "Realizēt digitālus mērķus, izveidojot un pielāgojot mājaslapu tieši pēc jūsu vajadzībām un ne tikai.",
        },
        {
          id: 1,
          text: "Mūsu mērķis ir nodrošināt jums mājaslapu, kas ne tikai izceļ jūsu uzņēmumu, bet arī palīdz jums sasniegt savus digitālos mērķus. Ar mums jūs varat būt droši, ka jūsu mājaslapa būs saderīga ar mūsdienīgajām prasībām un atbilstoša jūsu uzņēmuma vērtībām.",
        },
        { id: 2, text: "Mājas lapu izstrāde" },
        {
          id: 3,
          text: "Ikvienam klientam vajadzības ir unikālas, tāpēc mūsu komanda strādās ar jums, lai izveidotu mājaslapu, kas atspoguļo jūsu zīmolu un piedāvājumu. Cena sākot no 149,- eiro.",
        },
        { id: 4, text: "Internetveikalu izstrāde" },
        {
          id: 5,
          text: "Varēsiet ērti uzsākt un vadīt savu e-komercijas biznesu, nemazinoties ar tehniskiem aspektiem. Mēs uzņemsimies gandrīz visu smagāko darbu, lai jūs varētu koncentrēties uz pārdošanu un jūsu uzņēmuma attīstību. Mūsu mērķis ir nodrošināt, ka jūsu interneta veikals ir pievilcīgs, efektīvs un veicina jūsu biznesa izaugsmi. Cena sākot no 699,- eiro.",
        },
        { id: 6, text: "Satura vadības sistēma" },
        {
          id: 7,
          text: "Digitālais administratora panelis, kas ne tikai palīdz uzturēt mājaslapas saturu, bet arī piedāvā praktiskas un dinamiskas funkcijas, kas padara jūsu ikdienu efektīvāku. Jums nav jābūt tehniskam ekspertam, lai izmantotu šo spēcīgo rīku jūsu uzņēmuma labā. Cena sākot no 299,- eiro.",
        },
        { id: 8, text: "Grafiskais dizains" },
        {
          id: 9,
          text: "Dot jums radošu atbalstu, padarot jūsu vizuālo identitāti piesaistošu un atmiņā paliekošu. Neatkarīgi no tā, vai jums nepieciešams atsvaidzināt esošo dizainu, vai arī jūs meklējat jaunas idejas, mēs esam šeit, lai palīdzētu jums izcelties un piesaistīt jūsu mērķauditoriju. Cena sākot no 99,- eiro.",
        },
        { id: 10, text: "Visi pakalpojumi" },
      ],
    },
    {
      id: 3,
      name: "motivation-banner",
      text: [
        {
          id: 0,
          text: "🎉 Iekļauts izvēloties veidot savu mājas lapu pie mums",
        },
        {
          id: 1,
          text: "Mūsu aizraušanās ir radīt jaunus risinājumus, lai veicināt uzņēmumu izaugsmi.",
        },
        {
          id: 2,
          text: "Galvenais ir spert pirmo soli un saprast, kādas ir nākamās rīcības. Sazinieties ar mums jau šodien, lai uzzinātu vairāk par mūsu pakalpojumiem un to, kā mēs varam jums palīdzēt pārvērst jūsu idejas realitātē.",
        },
      ],
      motivation_right: [
        {
          id: 0,
          text: "Mūsdienās mājaslapas ir nepieciešamas izskatīties lieliski arī mobilajās ierīcēs. Mēs nodrošinam 100% responsīvu dizainu.",
        },
        {
          id: 1,
          text: "Pie katra dizaina, neatkarīgi, vai tas ir web projekts vai mobilā aplikācija, mēs pieejam individuāli.",
        },
        {
          id: 2,
          text: "Biznesa logo ir kā zīmogs, ja jūsu biznesam nav logo, mēs Jums palīdzēsim.",
        },
        {
          id: 3,
          text: "Veicam atbilstošu SEO (meklētājprogrammu optimizāciju), vadoties pēc Google prasībām.",
        },
        {
          id: 4,
          text: "Mājaslapas garantija un bezmaksas konsultācijas mājas lapas izstrādes laikā.",
        },
      ],
    },
    { id: 4, name: "review", text: [{ id: 0, text: "Pievienot atsauksmi" }] },
    {
      id: 5,
      name: "hosting",
      text: [
        { id: 0, text: "Web un e-pasta hostings" },
        {
          id: 1,
          text: "Mēs nodrošinam uzticamu pamatu jūsu mājaslapas darbībai tiešsaistē. Ar mums jūs varēsiet izvairīties no tehnikas sarežģījumiem un uzticēties mūsu komandai, lai nodrošinātu, ka jūsu mājaslapa vienmēr ir pieejama un darbojas efektīvi.",
        },
        {
          id: 2,
          text: "Profesionāls e-pasta risinājums ar jūsu pašu domēna nosaukumu. Šāds e-pasts rada uzticību un atpazīstamību, uzlabojot jūsu uzņēmuma tēlu. Mēs nodrošinām drošu un viegli pārvaldāmu e-pasta platformu, kas atbilst jūsu vajadzībām komunikācijā un sadarbībā.",
        },
        { id: 3, text: "Uzzināt vairāk" },
      ],
    },
  ];

  const dataEnglish = [
    {
      id: 0,
      name: "welcome-banner",
      text: [
        {
          id: 0,
          text: "🤝 Website development for your business or organization.",
        },
        {
          id: 1,
          text: "Our website development services are available for all budgets, so you can start today.",
        },
        {
          id: 2,
          text: "We offer a full range of web services, from business cards and landing page design to complex content management systems. Custom design. Web and email hosting options, worry-free about where to host your website.",
        },
        {
          id: 3,
          text: "Learn more",
        },
      ],
    },
    {
      id: 1,
      name: "consultation-banner",
      text: [
        {
          id: 0,
          text: "In the digital age, having a website or web application presence can be crucial for businesses and individuals looking to present themselves online.",
        },
        {
          id: 1,
          text: "We offer free consultations where we can help you choose from platform selection to designing, creating, and publishing your website. Also, information about costs.",
        },
        {
          id: 2,
          text: "Get consultation",
        },
      ],
    },
    {
      id: 2,
      name: "services-banner",
      text: [
        {
          id: 0,
          text: "Achieve digital goals by creating and customizing a website tailored to your needs and beyond.",
        },
        {
          id: 1,
          text: "Our goal is to provide you with a website that not only highlights your business but also helps you achieve your digital goals. With us, you can be confident that your website will be compatible with modern requirements and aligned with your company values.",
        },
        {
          id: 2,
          text: "Website development",
        },
        {
          id: 3,
          text: "Every client's needs are unique, so our team will work with you to create a website that reflects your brand and offerings. Prices starting from €149.",
        },
        {
          id: 4,
          text: "E-commerce development",
        },
        {
          id: 5,
          text: "You can easily start and manage your e-commerce business without getting bogged down by technical aspects. We will handle most of the heavy lifting so you can focus on sales and growing your business. Our goal is to ensure that your online store is attractive, efficient, and promotes business growth. Prices starting from €699.",
        },
        {
          id: 6,
          text: "Content Management System",
        },
        {
          id: 7,
          text: "A digital admin panel that not only helps maintain website content but also offers practical and dynamic features that make your daily operations more effective. You don't need to be a technical expert to use this powerful tool for the benefit of your business. Prices starting from €299.",
        },
        {
          id: 8,
          text: "Graphic Design",
        },
        {
          id: 9,
          text: "Providing you with creative support to make your visual identity engaging and memorable. Whether you need to refresh your existing design or you're looking for new ideas, we are here to help you stand out and attract your target audience. Prices starting from €99.",
        },
        {
          id: 10,
          text: "All services",
        },
      ],
    },
    {
      id: 3,
      name: "motivation-banner",
      text: [
        {
          id: 0,
          text: "🎉 Included when you choose to build your website with us",
        },
        {
          id: 1,
          text: "Our passion is to create new solutions to promote business growth.",
        },
        {
          id: 2,
          text: "The key is to take the first step and understand the next actions. Contact us today to learn more about our services and how we can help you turn your ideas into reality.",
        },
      ],
      motivation_right: [
        {
          id: 0,
          text: "In the modern era, websites need to look great on mobile devices as well. We provide 100% responsive design.",
        },
        {
          id: 1,
          text: "For every design, whether it's a web project or a mobile app, we are available individually.",
        },
        {
          id: 2,
          text: "A business logo is like a stamp; if your business doesn't have a logo, we'll help you create one.",
        },
        {
          id: 3,
          text: "We perform appropriate SEO (search engine optimization) following Google's requirements.",
        },
        {
          id: 4,
          text: "Website guarantee and free consultations during the website development.",
        },
      ],
    },
    {
      id: 4,
      name: "review",
      text: [
        {
          id: 0,
          text: "Add a review",
        },
      ],
    },
    {
      id: 5,
      name: "hosting",
      text: [
        {
          id: 0,
          text: "Web and Email Hosting",
        },
        {
          id: 1,
          text: "We provide a reliable foundation for your website to operate online. With us, you can avoid technical complications and trust our team to ensure that your website is always accessible and functioning effectively.",
        },
        {
          id: 2,
          text: "Professional email solution with your own domain name. Such an email enhances trust and recognition, improving your company's image. We provide a secure and easy-to-manage email platform that suits your communication and collaboration needs.",
        },
        {
          id: 3,
          text: "Learn more",
        },
      ],
    },
  ];

  const [data, setData] = useState([]);

  //welcome banner slideshow
  const [slideCount, setSlideCount] = useState(0);

  const slidesLatvian = [
    {
      id: 1,
      name: "Landing lapas izstrāde",
      desc: "Ātri izstrādājams, pārdošanai optimizēts risinājums konkrētam mērķim",
      img: "./img/welcome-banner-slideshow/landing-page.jpg",
    },
    {
      id: 2,
      name: "Admin paneļu izveide",
      desc: "Web aplikācijas pārvalde, atskaišu un tml. uzdevumu izpildes lapa",
      img: "./img/welcome-banner-slideshow/admin-panel.jpg",
    },
    {
      id: 3,
      name: "Interneta veikals",
      desc: "Nestandarta eKomercijas risinājumu izstrāde",
      img: "./img/welcome-banner-slideshow/ecommerce.jpg",
    },
    {
      id: 4,
      name: "Satura vadības sistēma",
      desc: "Viegli un efektīvi pārvaldīt un publicēt digitālo saturu, piemēram, mājaslapas saturu, blogus, ziņu lapas, produktu katalogus un citu saturu tiešsaistē.",
      img: "./img/welcome-banner-slideshow/cms.jpg",
    },
    {
      id: 5,
      name: "Mobilo aplikāciju izveide",
      desc: "Viedtelefoniem un planšetdatoriem. Tāpat kā web aplikācijas, mobilās aplikācijas nodrošina lietotājiem dažādus pakalpojumus un funkcijas, taču tās ir izstrādātas, ņemot vērā mobilās ierīces specifiku un ierobežojumus.",
      img: "./img/welcome-banner-slideshow/mobile-app.jpg",
    },
    {
      id: 6,
      name: "Personalizēta dizaina izstrāde",
      desc: "Paredzēts specifiskai auditorijai vai lietotājam, ņemot vērā viņu vajadzības, intereses. Tas ietver individuālu pieeju, kurā tiek pielāgota funkcionalitāte, lai atbilstu konkrētiem klienta vēlmēm un prasībām.",
      img: "./img/welcome-banner-slideshow/design.jpg",
    },
  ];

  const slidesEnglish = [
    {
      id: 1,
      name: "Landing Page Development",
      desc: "Quickly developed, sales-optimized solution for a specific purpose.",
      img: "./img/welcome-banner-slideshow/landing-page.jpg",
    },
    {
      id: 2,
      name: "Admin Panel Creation",
      desc: "Web application management, dashboard for reports and task execution.",
      img: "./img/welcome-banner-slideshow/admin-panel.jpg",
    },
    {
      id: 3,
      name: "Online Store",
      desc: "Development of non-standard e-commerce solutions.",
      img: "./img/welcome-banner-slideshow/ecommerce.jpg",
    },
    {
      id: 4,
      name: "Content Management System",
      desc: "Efficiently manage and publish digital content online, such as website content, blogs, news pages, product catalogs, and other content.",
      img: "./img/welcome-banner-slideshow/cms.jpg",
    },
    {
      id: 5,
      name: "Mobile App Development",
      desc: "For smartphones and tablets. Just like web applications, mobile apps provide users with various services and features, but they are designed taking into account the specifics and limitations of mobile devices.",
      img: "./img/welcome-banner-slideshow/mobile-app.jpg",
    },
    {
      id: 6,
      name: "Custom Design Development",
      desc: "Designed for a specific audience or user, considering their needs and interests. It involves an individual approach where functionality is adapted to meet specific customer preferences and requirements.",
      img: "./img/welcome-banner-slideshow/design.jpg",
    },
  ];

  const [slides, setSlides] = useState({});

  //review banner slideshow
  const [reviewCount, setReviewCount] = useState(0);

  const reviewsLatvian = [
    {
      id: 1,
      name: "Agnese V.",
      review:
        "jaaigroup ir mans uzticamais mājaslapu glabātājs. Viņu sniegtais ātrums un stabilitāte ir ievērojama. Es vienmēr saņemu palīdzību no viņu draudzīgā klientu atbalsta, ja radās jautājumi vai problēmas.",
      img: "./img/reviews/agnese-v.png",
    },
    {
      id: 2,
      name: "John Q.",
      review:
        "Darbs ar jaaigroup bija prieks. Viņu komanda izveidoja lietotni, kas manām vajadzībām pilnībā atbilst. Viņi ne tikai radīja funkcionalitāti, bet arī pievērsa uzmanību detaļām, kas padara lietotni lielisku.",
      img: "./img/reviews/john-q.png",
    },
    {
      id: 3,
      name: "Liene Z.",
      review:
        "Ne tikai profesionāla, bet arī draudzīga. Viņa pastāvīgi informēja mani par projektu un veica grozījumus pēc manām vēlmēm. Rezultāts ir lieliski darināta vietne, kas patīk gan man, gan klientiem.",
      img: "./img/reviews/liene-z.png",
    },
    {
      id: 4,
      name: "Anete L.",
      review:
        "Viņi apzinās mājaslapu lietotāju vajadzības un radīja mums lielisku pieredzi. Komunikācija ar viņiem bija gluda, un viņi strādāja ātri un efektīvi.",
      img: "./img/reviews/anete-l.png",
    },
    {
      id: 5,
      name: "Gatis Ā.",
      review:
        "Man bija fantastiska pieredze ar jaaigroup. Viņi paņēma manu lietotnes ideju un pārvērta to realitātē. Lietotne ir lietotājam draudzīga un bez defektiem. Komandas ciešā darba dēļ liels paldies!",
      img: "./img/reviews/gatis-a.png",
    },
    {
      id: 6,
      name: "Marta R.",
      review:
        "Viņi sazinājās visu projektu garumā, nodrošinot, ka manas idejas tiek iekļautas. Mājaslapa, ko viņi izveidoja, ne tikai ir vizuāli pievilcīga, bet arī darbojas bez traucējumiem.",
      img: "./img/reviews/marta-r.png",
    },
    {
      id: 7,
      name: "Edgars S.",
      review:
        "Darbs ar jaaigroup bija patīkams un produktīvs. Viņi ieklausījās manās vēlmēs un piedāvāja kreatīvus risinājumus. Esmu sajūsmā par viņa veiktajiem uzlabojumiem, kas ir padarījuši manu vietni aizraujošu un pievilcīgu.",
      img: "./img/reviews/edgars-s.png",
    },
    {
      id: 8,
      name: "Kristaps M.",
      review:
        "Nav tikai talantīgi, bet arī ļoti piezemēti un draudzīgi džeki. Viņi strādāja kopā ar mani, lai izveidotu tieši to, ko es vēlējos. Esmu ļoti pateicīgs par viņa ieguldījumu un profesionālo pieeju.",
      img: "./img/reviews/kristaps-m.png",
    },
  ];

  const reviewsEnglish = [
    {
      id: 1,
      name: "Agnese V.",
      review:
        "jaaigroup is my reliable website host. Their provided speed and stability are remarkable. I always receive assistance from their friendly customer support whenever questions or issues arise.",
      img: "./img/reviews/agnese-v.png",
    },
    {
      id: 2,
      name: "John Q.",
      review:
        "Working with jaaigroup was a pleasure. Their team created an application that fully met my needs. They not only developed the functionality but also paid attention to details that make the application excellent.",
      img: "./img/reviews/john-q.png",
    },
    {
      id: 3,
      name: "Liene Z.",
      review:
        "Not only professional but also friendly. They kept me informed about the project and made changes according to my preferences. The result is a beautifully crafted website that is appealing to both me and my clients.",
      img: "./img/reviews/liene-z.png",
    },
    {
      id: 4,
      name: "Anete L.",
      review:
        "They understand the needs of website users and created a great experience for us. Communication with them was smooth, and they worked quickly and effectively.",
      img: "./img/reviews/anete-l.png",
    },
    {
      id: 5,
      name: "Gatis Ā.",
      review:
        "I had a fantastic experience with jaaigroup. They took my app idea and turned it into reality. The app is user-friendly and flawless. Thanks a lot for the hard work of the team!",
      img: "./img/reviews/gatis-a.png",
    },
    {
      id: 6,
      name: "Marta R.",
      review:
        "They stayed in touch throughout the entire project, ensuring that my ideas were incorporated. The website they created is not only visually appealing but also operates without any issues.",
      img: "./img/reviews/marta-r.png",
    },
    {
      id: 7,
      name: "Edgars S.",
      review:
        "Working with jaaigroup was pleasant and productive. They listened to my desires and offered creative solutions. I am thrilled with the improvements they made, making my site exciting and attractive.",
      img: "./img/reviews/edgars-s.png",
    },
    {
      id: 8,
      name: "Kristaps M.",
      review:
        "Not only talented but also very approachable and friendly folks. They worked alongside me to create exactly what I wanted. I am very grateful for their contribution and professional approach.",
      img: "./img/reviews/kristaps-m.png",
    },
  ];

  const [reviews, setReviews] = useState([]);

  const navigatePage = (elem) => {
    const element = document.querySelector(`#${elem}`);
    element.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  //framer motion
  const transition = { duration: 0.5, ease: "easeInOut" };

  useEffect(() => {
    if (reduxLang) {
      if (reduxLang === "lv") {
        setData(dataLatvian);
        setSlides(slidesLatvian);
        setReviews(reviewsLatvian);
      } else if (reduxLang === "eng") {
        setData(dataEnglish);
        setSlides(slidesEnglish);
        setReviews(reviewsEnglish);
      }
    }
    //eslint-disable-next-line
  }, [reduxLang]);

  return (
    <Wrapper>
      <AnimatedComponent>
        <WelcomeBanner>
          <div className="left">
            <p className="green-bar">{data[0]?.text[0]?.text}</p>

            <h1>{data[0]?.text[1]?.text}</h1>

            <p className="description">{data[0]?.text[2]?.text}</p>

            <div
              className="btn slide"
              onClick={() => navigatePage("services-banner")}
            >
              {data[0]?.text[3]?.text}
            </div>
          </div>

          <div className="right">
            <motion.div
              className="welcome-banner-slideshow"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={transition}
            >
              <motion.img
                key={slideCount}
                src={slides[slideCount]?.img}
                alt="slideshow-img"
                className="slideshow-image"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={transition}
              />
              <div className="welcome-banner-slideshow-desc">
                <motion.div
                  key={slideCount}
                  className="welcome-banner-slideshow-desc-left"
                  initial={{ x: -50, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  exit={{ x: 50, opacity: 0 }}
                  transition={transition}
                >
                  <h3 className="slide-title">{slides[slideCount]?.name}</h3>
                  <p className="slide-description">
                    {slides[slideCount]?.desc}
                  </p>
                </motion.div>

                <div className="welcome-banner-slideshow-desc-right">
                  {slideCount !== 0 && (
                    <motion.div
                      onClick={() =>
                        setSlideCount((slideCount) => slideCount - 1)
                      }
                      className="welcome-banner-slideshow-desc-right-btn"
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                    >
                      <ArrowLeft className="icon" />
                    </motion.div>
                  )}
                  {slideCount + 1 !== slides?.length && (
                    <motion.div
                      onClick={() =>
                        setSlideCount((slideCount) => slideCount + 1)
                      }
                      className="welcome-banner-slideshow-desc-right-btn"
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.7 }}
                    >
                      <ArrowRight className="icon" />
                    </motion.div>
                  )}
                </div>
              </div>
            </motion.div>
          </div>
        </WelcomeBanner>
      </AnimatedComponent>

      <AnimatedComponent>
        <ConsultationBanner>
          <div className="images-left">
            <div className="images-banner">
              <img
                className="image-banner-1"
                src="./img/consultation_banner_img1.jpg"
                alt="banner_img"
              />
              <img
                className="image-banner-2"
                src="./img/consultation_banner_img2.jpg"
                alt="banner_img"
              />
            </div>
          </div>

          <div className="banner-text-right">
            <h2>{data[1]?.text[0]?.text}</h2>

            <p className="description">{data[1]?.text[1]?.text}</p>

            <Link to="/contact/consultation">
              <div className="btn slide">{data[1]?.text[2]?.text}</div>
            </Link>
          </div>
        </ConsultationBanner>
      </AnimatedComponent>

      <AnimatedComponent>
        <ServicesBanner id="services-banner">
          <div className="services-banner-background"></div>

          <div className="services-banner-top">
            <h2 className="services-banner-top-left">
              {data[2]?.text[0]?.text}
            </h2>

            <p className="services-banner-top-right">
              {data[2]?.text[1]?.text}
            </p>
          </div>

          <div className="services-options">
            <div className="services-options-single">
              <div className="services-options-single-service">
                <p className="services-options-single-count">01</p>

                <div className="services-options-single-service-details">
                  <h2>{data[2]?.text[2]?.text}</h2>

                  <p>{data[2]?.text[3]?.text}</p>
                </div>
              </div>

              <Link to="/services/web">
                <ArrowUpRight className="icon" />
              </Link>
            </div>

            <div className="services-options-single-desc-mobile">
              <p>{data[2]?.text[3]?.text}</p>
            </div>

            <div className="services-options-single">
              <div className="services-options-single-service">
                <p className="services-options-single-count">02</p>

                <div className="services-options-single-service-details">
                  <h2>{data[2]?.text[4]?.text}</h2>

                  <p>{data[2]?.text[5]?.text}</p>
                </div>
              </div>

              <Link to="/services/ecommerce">
                <ArrowUpRight className="icon" />
              </Link>
            </div>

            <div className="services-options-single-desc-mobile">
              <p>{data[2]?.text[5]?.text}</p>
            </div>

            <div className="services-options-single">
              <div className="services-options-single-service">
                <p className="services-options-single-count">03</p>

                <div className="services-options-single-service-details">
                  <h2>{data[2]?.text[6]?.text}</h2>

                  <p>{data[2]?.text[7]?.text}</p>
                </div>
              </div>

              <Link to="/services/cms">
                <ArrowUpRight className="icon" />
              </Link>
            </div>

            <div className="services-options-single-desc-mobile">
              <p>{data[2]?.text[7]?.text}</p>
            </div>

            <div className="services-options-single">
              <div className="services-options-single-service">
                <p className="services-options-single-count">04</p>

                <div className="services-options-single-service-details">
                  <h2>{data[2]?.text[8]?.text}</h2>

                  <p>{data[2]?.text[9]?.text}</p>
                </div>
              </div>

              <Link to="/services/design">
                <ArrowUpRight className="icon" />
              </Link>
            </div>

            <div className="services-options-single-desc-mobile">
              <p>{data[2]?.text[9]?.text}</p>
            </div>
          </div>

          <Link to="/services" onClick={() => window.scrollTo(0, 0)}>
            <div className="btn slide">{data[2]?.text[10]?.text}</div>
          </Link>
        </ServicesBanner>
      </AnimatedComponent>

      <AnimatedComponent>
        <MotivationBanner>
          <div className="left">
            <p className="green-bar">{data[3]?.text[0]?.text}</p>

            <h2>{data[3]?.text[1]?.text}</h2>

            <p className="description">{data[3]?.text[2]?.text}</p>
          </div>

          <div className="right">
            {data[3]?.motivation_right?.map((item) => (
              <p key={item?.id}>{item?.text}</p>
            ))}
          </div>
        </MotivationBanner>
      </AnimatedComponent>

      <AnimatedComponent>
        <ReviewBanner>
          <div className="review-single">
            <motion.div
              key={reviewCount}
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -100 }}
              transition={{ duration: 0.5 }}
              style={{ display: "flex", gap: "20px" }}
            >
              <img src={reviews[reviewCount]?.img} alt="review-img" />
              <div className="review-single-info">
                <h3>{reviews[reviewCount]?.name}</h3>
                <p>{reviews[reviewCount]?.review}</p>
              </div>
            </motion.div>
            <div className="review-nav">
              {reviewCount !== 0 && (
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <ArrowIosBackOutline
                    onClick={() =>
                      setReviewCount((reviewCount) => reviewCount - 1)
                    }
                    className="icon"
                  />
                </motion.div>
              )}
              {reviewCount + 1 !== reviews?.length && (
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <ArrowIosForwardOutline
                    onClick={() =>
                      setReviewCount((reviewCount) => reviewCount + 1)
                    }
                    className="icon"
                  />
                </motion.div>
              )}
            </div>
          </div>

          <Link to="/contact/review">
            <div className="btn slide">{data[4]?.text[0]?.text}</div>
          </Link>
        </ReviewBanner>
      </AnimatedComponent>

      <AnimatedComponent>
        <HostingBanner>
          <div className="hosting-desc">
            <h2>{data[5]?.text[0]?.text}</h2>

            <p>{data[5]?.text[1]?.text}</p>
            <p>{data[5]?.text[2]?.text}</p>

            <Link to="/hosting" onClick={() => window.scrollTo(0, 0)}>
              <div className="btn slide">{data[5]?.text[3]?.text}</div>
            </Link>
          </div>

          <div className="hosting-img">
            <div className="images-banner">
              <img
                className="image-banner-1"
                src="./img/hosting_banner_img1.jpg"
                alt="banner_img"
              />
              <img
                className="image-banner-2"
                src="./img/hosting_banner_img2.jpg"
                alt="banner_img"
              />
            </div>
          </div>
        </HostingBanner>
      </AnimatedComponent>

      <AnimatedComponent>
        <OfferBanner />
      </AnimatedComponent>
    </Wrapper>
  );
};

const HostingBanner = styled.div`
  max-width: 1200px;
  margin: 160px auto 120px;
  padding: 0 20px;
  gap: 2.5rem;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 840px) {
    flex-direction: column;
    margin: 100px auto 70px;

    .hosting-desc,
    .hosting-img {
      width: 100% !important;

      p {
        max-width: 95% !important;
      }
    }

    .images-banner {
      justify-content: center !important;

      .image-banner-2 {
        bottom: -36px !important;
        min-width: 160px !important;
        max-width: 250px !important;
        max-height: 240px !important;

        @media only screen and (max-width: 720px) {
          right: 20px !important;
        }
      }
    }
  }

  .hosting-desc {
    width: 50%;

    .slide:hover,
    .slide:focus {
      box-shadow: inset 10rem 0 0 0 #121315;
    }

    .btn {
      background: #376058;
      color: white;
      text-decoration: none;
      display: inline-block;
      padding: 12px 20px;
      font-size: 0.8rem;
      border-radius: 10px;
      transition: 0.4s ease-in-out;
    }

    h2 {
      font-size: 1.4rem;
      max-width: 450px;
      margin: 20px 0 0 0;
      color: #121315;
      line-height: 1.3;
    }

    p {
      color: #4d4d4d;
      font-size: 0.9rem;
      margin: 20px 0;
      max-width: 80%;
      line-height: 1.3;
    }
  }

  .hosting-img {
    width: 50%;

    .images-banner {
      position: relative;
      display: flex;
      justify-content: end;

      .image-banner-1 {
        max-width: 90%;
        min-width: 300px;
        max-height: 350px;
        object-fit: cover;
        border-radius: 10px;
      }

      .image-banner-2 {
        max-width: 60%;
        min-width: 300px;
        max-height: 300px;
        object-fit: cover;
        border-radius: 10px;
        border: 12px solid white;
        position: absolute;
        bottom: -5rem;
        right: 10rem;
      }
    }
  }
`;

const ReviewBanner = styled.div`
  max-width: 1200px;
  margin: 100px auto;
  padding: 0 20px;

  @media only screen and (max-width: 600px) {
    margin: 50px auto;

    .review-single {
      gap: 10px !important;

      p {
        max-width: 100% !important;
        width: 100% !important;
      }
    }
  }

  .slide:hover,
  .slide:focus {
    box-shadow: inset 10rem 0 0 0 #121315;
  }

  .btn {
    background: #376058;
    color: white;
    text-decoration: none;
    display: inline-block;
    padding: 12px 20px;
    font-size: 0.8rem;
    border-radius: 10px;
    transition: 0.4s ease-in-out;
    margin: 40px 0 0 0;
    float: right;
  }

  .review-single {
    display: flex;
    gap: 20px;
    justify-content: space-between;

    img {
      width: 70px;
      height: 70px;
      border-radius: 50%;
    }

    .review-single-info {
      h3 {
        font-size: 1.2rem;
        line-height: 1.3;
      }

      p {
        color: #4d4d4d;
        font-size: 0.9rem;
        max-width: 80%;
        min-width: 200px;
        line-height: 1.3;
        margin: 10px 0 0 0;
      }
    }

    .review-nav {
      display: flex;
      gap: 5px;
      cursor: pointer;

      .icon {
        width: 24px;
        height: 24px;
      }
    }
  }
`;

const MotivationBanner = styled.div`
  max-width: 1200px;
  margin: 30px auto 20px;
  gap: 1.5rem;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;

  @media only screen and (max-width: 760px) {
    .left,
    .right {
      width: 50%;
    }
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    gap: 0;

    .left,
    .right {
      width: 100% !important;
    }

    .right {
      padding-top: 0px !important;
    }
  }

  .right {
    padding-top: 50px;

    p {
      color: #4d4d4d;
      font-size: 0.9rem;
      margin: 0 0 20px;
      max-width: 700px;
      line-height: 1.3;
    }
  }

  .left {
    .description {
      color: #4d4d4d;
      font-size: 0.9rem;
      margin: 30px 0;
      max-width: 80%;
      line-height: 1.3;
    }

    h2 {
      font-size: 1.6rem;
      max-width: 450px;
      margin: 20px 0 20px;
      color: #121315;
      line-height: 1.3;
    }

    .green-bar {
      font-size: 0.8rem;
      background: #e7f4f4;
      padding: 10px;
      display: inline-block;
      border-radius: 10px;
      color: #376058;
      font-weight: 600;
    }
  }
`;

const ServicesBanner = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;

  a {
    display: flex;
    justify-content: right;
    text-decoration: none;
    color: white;
  }

  .slide:hover,
  .slide:focus {
    box-shadow: inset 10rem 0 0 0 #121315;
  }

  .btn {
    background: #376058;
    color: white;
    text-decoration: none;
    display: inline-block;
    padding: 12px 20px;
    font-size: 0.8rem;
    border-radius: 10px;
    transition: 0.4s ease-in-out;
    margin: 20px 0;
  }

  .services-options {
    max-width: 1200px;
    margin: 0px auto 20px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .services-options-single-desc-mobile {
      display: none;

      p {
        color: #e0e0e0;
        font-size: 0.9rem;
        line-height: 1.3;
      }

      @media only screen and (max-width: 660px) {
        display: block;
      }
    }

    .services-options-single {
      display: flex;
      gap: 2rem;
      justify-content: space-between;
      position: relative;

      .services-options-single-service {
        display: flex;
        gap: 2rem;
        margin-top: 20px;

        .services-options-single-count {
          font-size: 2rem;
          font-weight: 600;
          color: white;
        }

        .services-options-single-service-details {
          max-width: 80%;

          @media only screen and (max-width: 660px) {
            max-width: 100% !important;
          }

          h2 {
            font-size: 1.4rem;
            color: white;
            margin-bottom: 15px;
          }

          p {
            color: #e0e0e0;
            font-size: 0.9rem;
            line-height: 1.3;

            @media only screen and (max-width: 660px) {
              display: none;
            }
          }
        }
      }

      a {
        color: #e0e0e0;
        position: relative;
        padding-top: 40px;
        transition: 0.4s ease-in-out;

        @media only screen and (max-width: 660px) {
          padding-top: 25px;
        }

        .icon {
          width: 32px;
          height: 32px;
          transition: 0.2s ease-in-out;
        }

        :hover {
          color: #376058;
        }
      }
    }
  }

  .services-banner-top {
    max-width: 1200px;
    margin: 30px auto 20px;
    gap: 3rem;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;

    @media only screen and (max-width: 680px) {
      flex-direction: column;

      .services-banner-top-left,
      .services-banner-top-right {
        max-width: 100% !important;
      }
    }

    .services-banner-top-left {
      font-size: 1.4rem;
      max-width: 400px;
      color: white;
      line-height: 1.3;
    }

    .services-banner-top-right {
      max-width: 400px;
      color: #e0e0e0;
      font-size: 0.9rem;
      line-height: 1.3;
    }
  }

  .services-banner-background {
    background: #034c4d;
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
`;

const ConsultationBanner = styled.div`
  max-width: 1200px;
  margin: 80px auto 120px;
  padding: 0 20px;
  gap: 2.5rem;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 720px) {
    flex-direction: column;
    margin: 40px auto 60px;

    .images-left,
    .banner-text-right {
      width: 100% !important;

      .description {
        max-width: 100% !important;
      }

      .image-banner-2 {
        bottom: -36px !important;
        min-width: 160px !important;
        max-width: 250px !important;
        max-height: 240px !important;
      }
    }
  }

  .images-left {
    width: 50%;

    .images-banner {
      position: relative;

      .image-banner-1 {
        max-width: 90%;
        min-width: 300px;
        object-fit: cover;
        border-radius: 10px;
      }

      .image-banner-2 {
        max-width: 60%;
        max-height: 300px;
        object-fit: cover;
        border-radius: 10px;
        border: 12px solid white;
        position: absolute;
        bottom: -5rem;
        right: -1rem;

        @media only screen and (max-width: 900px) {
          bottom: -10rem;
        }
      }
    }
  }

  .banner-text-right {
    width: 50%;

    h2 {
      font-size: 1.4rem;
      max-width: 450px;
      margin: 20px 0 0 0;
      color: #121315;
      line-height: 1.3;
    }

    .description {
      color: #4d4d4d;
      font-size: 0.9rem;
      margin: 30px 0;
      max-width: 80%;
      line-height: 1.3;
    }

    .slide:hover,
    .slide:focus {
      box-shadow: inset 10rem 0 0 0 #121315;
    }

    .btn {
      background: #376058;
      color: white;
      text-decoration: none;
      display: inline-block;
      padding: 12px 20px;
      font-size: 0.8rem;
      border-radius: 10px;
      transition: 0.4s ease-in-out;
    }
  }
`;

const WelcomeBanner = styled.div`
  max-width: 1200px;
  padding: 0 20px;
  margin: 0 auto;
  gap: 5rem;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 660px) {
    flex-direction: column;

    .left,
    .right {
      width: 100% !important;
    }

    .left {
      margin-top: 20px !important;
    }
  }

  .right {
    width: 45%;

    .welcome-banner-slideshow {
      position: relative;

      img {
        width: 100%;
        min-height: 350px;
        max-height: 350px;
        object-fit: cover;
      }

      .welcome-banner-slideshow-desc {
        background: #e0f3ec;
        padding: 15px 20px;
        position: relative;
        top: -5px;
        display: flex;
        gap: 10px;
        justify-content: space-between;
        overflow-y: hidden;

        .welcome-banner-slideshow-desc-left {
          display: flex;
          flex-direction: column;
          gap: 5px;

          h3 {
            font-size: 0.9rem;
          }

          p {
            font-size: 0.8rem;
            max-width: 80%;
            color: #4d4d4d;
          }
        }

        .welcome-banner-slideshow-desc-right {
          display: flex;
          gap: 10px;

          .welcome-banner-slideshow-desc-right-btn {
            background: white;
            height: 32px;
            width: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            cursor: pointer;
            transition: 0.2s ease-in-out;

            .icon {
              width: 16px;
              height: 16px;
            }
          }

          .welcome-banner-slideshow-desc-right-btn:hover {
            background: #376058;

            .icon {
              color: white;
            }
          }
        }
      }
    }
  }

  .left {
    margin-top: 80px;
    width: 45%;

    .slide:hover,
    .slide:focus {
      box-shadow: inset 8rem 0 0 0 #376058;
    }

    .btn {
      background: #121315;
      color: white;
      text-decoration: none;
      display: inline-block;
      padding: 12px 20px;
      font-size: 0.8rem;
      border-radius: 10px;
      transition: 0.4s ease-in-out;
      cursor: pointer;
    }

    .description {
      color: #4d4d4d;
      font-size: 0.9rem;
      margin: 30px 0;
      max-width: 80%;
      line-height: 1.3;

      @media only screen and (max-width: 760px) {
        max-width: 100%;
      }
    }

    h1 {
      font-size: 1.6rem;
      max-width: 450px;
      margin: 20px 0 0 0;
      color: #121315;
      line-height: 1.3;
    }

    .green-bar {
      font-size: 0.8rem;
      background: #e7f4f4;
      padding: 10px;
      display: inline-block;
      border-radius: 10px;
      color: #376058;
      font-weight: 600;
    }
  }
`;

const Wrapper = styled.div``;

export default Home;
