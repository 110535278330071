import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

//router
import { useNavigate } from 'react-router-dom';

//redux
import { useSelector } from 'react-redux';
import { selectLanguage } from '../redux/variables';

const PageNotFound = () => {

    const reduxLang = useSelector(selectLanguage);

    const [count, setCount] = useState(3);
    const navigate = useNavigate();

    const textLatvian = ['Izskatās, ka šāda lapa neeksistē.',
    'Novirzīsim tevi uz sākumlapu pēc',
    'sekundēm']

    const textEnglish = ['It seems that this page does not exist.',
    'We will redirect you to the home page in',
    'seconds.']

    const [text, setText] = useState(textLatvian);

    useEffect(() => {
        setCount(3);

        setInterval(() => {
            setCount(count => count - 1);
        }, 1000)
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        count === 0 && navigate('/');
        //eslint-disable-next-line
    }, [count])

    //language handler from redux
    useEffect(() => {
        if(reduxLang === 'lv'){ 
            setText(textLatvian);
        } else if(reduxLang === 'eng'){
            setText(textEnglish);
        }

        //eslint-disable-next-line
    }, [reduxLang]);

    return (
        <Wrapper>   
            <div className='content'>
                <h1>404</h1>
                <p>{text?.[0]}</p>
                <p>{text?.[1]} {count} {text?.[2]}</p>
            </div>
        </Wrapper>
    )
}

const Wrapper = styled.div`
max-width: 1200px;
min-height: 60vh;
margin: 40px auto;
display: flex;
justify-content: center;
align-items: center;

.content {
    text-align: center;
    position: relative;

    h1 {
        position: absolute;
        top: -66px;
        left: 50%;
        transform: translateX(-50%);
        opacity: .3;
        font-size: 5rem;
    }

    p {
        font-size: 1rem;
    }
}
`

export default PageNotFound