import React, { useState, useEffect } from "react";
import styled from "styled-components";

//components
import PageHeading from "../components/PageHeading";
import OfferBanner from "../components/OfferBanner";
import AnimatedComponent from "../components/AnimatedComponent";

//redux
import { useSelector } from "react-redux";
import { selectLanguage } from "../redux/variables";

//additionals
import { Helmet } from "react-helmet";

const About = () => {
  //redux
  const reduxLang = useSelector(selectLanguage);

  const [pageData, setPageData] = useState({});

  const pageDataLatvian = {
    name: "Par mums",
    desc: "Aktīvi strādājām pie esošajiem projektiem, sniedzot augstu standartu pakalpojumu klāstu.",
    img: "./img/pages/about.jpg",
  };

  const pageDataEnglish = {
    name: "About us",
    desc: "We are actively working on existing projects, providing a range of high standard services.",
    img: "./img/pages/about.jpg",
  };

  const [data, setData] = useState([]);

  const dataLatvian = [
    {
      id: 0,
      text: "Mūsu komandai ir vairāku gadu pieredze mājaslapu un tīmekļa lietotņu izstrādē, kas tiek veikta rūpīgi pielāgojoties katram klientam. Mēs pilnībā iedziļināmies klienta vēlmēs un mērķos, lai nodrošinātu, ka mūsu darbs ne tikai atspoguļo viņu redzējumu, bet arī iekļauj visus nepieciešamos funkcionalitātes un dizaina aspektus. Mēs esam partneri, kas gatavi sadarboties un kopā strādāt, lai nodrošinātu pilnīgu redzējuma īstenošanu un projekta veiksmi.",
    },
    {
      id: 1,
      text: "Katrs projekts, pie kura mēs strādājam, tiek uzskatīts par unikālu izaicinājumu. Mēs uzskatām, ka veiksmīga mājaslapas vai tīmekļa lietotnes izstrāde sākas ar rūpīgu uzklausīšanu, izpratni un detalizētu plānošanu. Tādēļ mēs ieguldām laiku, lai pilnībā saprastu klienta vajadzības un uzņēmējdarbības kontekstu, kas ļauj mums piedāvāt pielāgotus risinājumus, kas ne tikai atbilst klienta prasībām, bet arī veicina viņa mērķu sasniegšanu.",
    },
    {
      id: 2,
      text: "Mūsu komandā strādā speciālisti – front-end un back-end eksperti, kuri apvieno savas zināšanas un radošumu, lai radītu saskaņotu un augstas kvalitātes digitālo produktu. Front-end speciālisti nodrošina lietotājiem pievilcīgu un intuitīvu lietošanas pieredzi, bet back-end speciālisti rūpējas par tehniskajām detaļām un datu apstrādi, lai nodrošinātu, ka visi procesi darbojas efektīvi un droši.",
    },
  ];

  const dataEnglish = [
    {
      id: 0,
      text: "Our team has several years of experience in web development, which is carefully tailored to each client. We fully immerse ourselves in the clients wishes and goals to ensure that our work not only reflects their vision, but also includes all the necessary functionality and design aspects. We are partners who are ready to collaborate and work together to ensure the full implementation of the vision and the success of the project.",
    },
    {
      id: 1,
      text: "Each project we work on is considered a unique challenge. We believe that successful web development starts with careful listening, understanding, and detailed planning. Therefore, we invest time to fully understand the clients needs and business context, which allows us to offer customized solutions that not only meet the clients requirements, but also help them achieve their goals.",
    },
    {
      id: 2,
      text: "Our team consists of specialists - front-end and back-end experts, who combine their knowledge and creativity to create a consistent and high-quality digital product. Front-end specialists provide users with an attractive and intuitive user experience, while back-end specialists take care of the technical details and data processing to ensure that all processes run efficiently and securely.",
    },
  ];

  useEffect(() => {
    if (reduxLang) {
      if (reduxLang === "lv") {
        setPageData(pageDataLatvian);
        setData(dataLatvian);
      } else if (reduxLang === "eng") {
        setPageData(pageDataEnglish);
        setData(dataEnglish);
      }
    }
    //eslint-disable-next-line
  }, [reduxLang]);

  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>JAAIGROUP: Par Mums</title>
        <link rel="canonical" href="http://jaaigroup.com/" />
        <meta
          property="og:title"
          content="JAAIGROUP: Uzņēmums un Mūsu Stāsts"
        />
        <meta name="author" content="JAAIGROUP" />
        <meta
          name="description"
          content="Uzziniet vairāk par JAAIGROUP - mēs esam digitālais risinājumu sniedzējs, kas specializējas mājas lapu izstrādē, hostingā, SEO un digitālajā mārketingā. Mūsu komanda apvieno ekspertus no dažādām nozarēm, lai nodrošinātu holistiskus un efektīvus risinājumus jūsu biznesa izaugsmei. Ar pieredzi un dedikāciju, mēs palīdzam sasniegt jūsu digitālos mērķus."
        />
        <meta
          property="og:description"
          content="Uzziniet vairāk par JAAIGROUP - mēs esam digitālais risinājumu sniedzējs, kas specializējas mājas lapu izstrādē, hostingā, SEO un digitālajā mārketingā. Mūsu komanda apvieno ekspertus no dažādām nozarēm, lai nodrošinātu holistiskus un efektīvus risinājumus jūsu biznesa izaugsmei. Ar pieredzi un dedikāciju, mēs palīdzam sasniegt jūsu digitālos mērķus."
        />
        <meta property="og:locale" content="lv_LV" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="http://jaaigroup.com/" />
        <meta
          property="og:site_name"
          content="JAAIGROUP: Uzņēmums un Mūsu Stāsts"
        />
        <meta
          name="twitter:description"
          content="Uzziniet vairāk par JAAIGROUP - mēs esam digitālais risinājumu sniedzējs, kas specializējas mājas lapu izstrādē, hostingā, SEO un digitālajā mārketingā. Mēs darbojamies ar dedikāciju, lai palīdzētu jums sasniegt digitālos mērķus un sekmēt jūsu biznesa izaugsmi."
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content="JAAIGROUP: Uzņēmums un Mūsu Stāsts"
        />
        <meta
          name="keywords"
          content="Par mums, JAAIGROUP, digitālie risinājumi, mājas lapu izstrāde, hostinga pakalpojumi, SEO, digitālais mārketings, uzņēmuma stāsts, komandas pieredze, efektīvi risinājumi, holistisks pieeja, biznesa izaugsme"
        />
      </Helmet>

      <AnimatedComponent>
        <PageHeading data={pageData} />

        <div className="about-data">
          {data?.map((item) => (
            <p key={item?.id} className="about-data-text">
              {item?.text}
            </p>
          ))}
        </div>

        <OfferBanner />
      </AnimatedComponent>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .about-data {
    max-width: 1200px;
    margin: 40px auto;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 40px 0;

    .about-data-text {
      max-width: 80%;
      font-size: 1rem;
      padding: 0 20px;
      color: #121315;
      line-height: 1.4;
    }

    .about-data-text:nth-child(2n) {
      align-self: self-end;
    }

    @media only screen and (max-width: 660px) {
      margin: 0 auto !important;
      padding: 0 0 40px 0;

      .about-data-text {
        max-width: 95%;
      }
    }
  }
`;

export default About;
