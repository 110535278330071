import React, { useState, useEffect } from "react";
import styled from 'styled-components'

import { Link } from 'react-router-dom'

//redux
import { useSelector } from 'react-redux';
import { selectLanguage } from '../redux/variables';

const OfferBanner = () => {
  //redux
  const reduxLang = useSelector(selectLanguage);

  const [data, setData] = useState({});

  const dataLatvian = {
    h3: 'Mēs saprotam, ka katrs projekts ir unikāls, tāpēc mēs vienmēr cenšamies saprast mūsu klientu vēlmes un vajadzības, lai nodrošinātu, ka mūsu darbs atbilst viņu prasībām. Ja jums ir jautājumi vai interese par mūsu pakalpojumiem, lūdzu, sazinieties ar mums!',
    btn: 'Saņemt piedāvājumu'
  }

  const dataEnglish = {
    h3: 'We understand that each project is unique, so we always strive to understand our clients wishes and needs to ensure that our work meets their requirements. If you have any questions or are interested in our services, please contact us!',
    btn: 'Get a offer'
  }

  useEffect(() => {
    if(reduxLang){
        if(reduxLang === 'lv'){ 
          setData(dataLatvian);
        } else if(reduxLang === 'eng'){
          setData(dataEnglish);
        }
    }
    //eslint-disable-next-line
  }, [reduxLang])

    return <Wrapper>
        <div className='offer-banner-content'>
            <h3>“{data?.h3}”</h3>
            <Link to='/contact/service'><div className='btn slide'>{data?.btn}</div></Link>
        </div>
    </Wrapper>;
};

const Wrapper = styled.div`
width: 100vw;
background: #034C4D;
padding: 50px 20px 50px 0px;

.offer-banner-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font-size: 1.4rem;
    color: white;
    line-height: 1.3;   
    text-align: center;
    max-width: 600px;
    margin: 0 auto 40px;
    padding: 0 20px;
  }

  .slide:hover,
  .slide:focus {
    box-shadow: inset 15rem 0 0 0 #376058;
  }

  .btn {
    background: #121315;
    color: white;
    text-decoration: none;
    display: inline-block;
    padding: 12px 20px;
    font-size: .8rem;
    border-radius: 10px;
    transition: .4s ease-in-out;
  }
}

`

export default OfferBanner;
