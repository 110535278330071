import React, { useState, useEffect } from "react";
import styled from "styled-components";

//router
import { Link } from "react-router-dom";

//icon
import { Whatsapp } from "@styled-icons/bootstrap/Whatsapp";
import { Mail } from "@styled-icons/entypo/Mail";

//redux
import { useSelector } from "react-redux";
import { selectLanguage } from "../redux/variables";

//components
import AnimatedComponent from "./AnimatedComponent";

const Footer = () => {
  //redux
  const reduxLang = useSelector(selectLanguage);

  const [data, setData] = useState([]);

  const dataLatvian = [
    { id: 0, name: "h3", text: "Navigācija" },
    {
      id: 1,
      name: "menu",
      menu: [
        { id: 0, name: "Sākums", link: "/" },
        { id: 1, name: "Servisi", link: "/services" },
        { id: 2, name: "Cenas", link: "/pricing" },
        { id: 3, name: "Hostings", link: "/hosting" },
        { id: 4, name: "Par mums", link: "/about" },
      ],
    },
    {
      id: 3,
      name: "desc",
      text: [
        {
          id: 0,
          text: "Pilns web pakalpojumu klāsts, sākot no vizītkaršu, landing lapu izstrādi līdz sarežģītām satura vadības sistēmām. Personalizēts dizains. Web un e-pasta hostinga iespējas, bez uztraukuma, kur glabāt savu mājaslapu.",
        },
        { id: 1, text: "© Visas tiesības aizsargātas jaaigroup.com 2023 - 2024" },
      ],
    },
    {
      id: 4,
      name: "contacts",
      text: [
        { id: 0, text: "Tālr. Nr." },
        { id: 1, text: "E-pasts" },
        { id: 2, text: "Kontakti" },
      ],
    },
  ];

  const dataEnglish = [
    { id: 0, name: "h3", text: "Navigation" },
    {
      id: 1,
      name: "menu",
      menu: [
        { id: 0, name: "Home", link: "/" },
        { id: 1, name: "Services", link: "/services" },
        { id: 2, name: "Prices", link: "/pricing" },
        { id: 3, name: "Hosting", link: "/hosting" },
        { id: 4, name: "About Us", link: "/about" },
      ],
    },
    {
      id: 2,
      name: "desc",
      text: [
        {
          id: 0,
          text: "Full range of web services, starting from business cards and landing page development to complex content management systems. Custom design. Web and email hosting options, without worries, where to host your website.",
        },
        { id: 1, text: "© All rights reserved jaaigroup.com 2023 - 2024" },
      ],
    },
    {
      id: 3,
      name: "contacts",
      text: [
        { id: 0, text: "Phone Number" },
        { id: 1, text: "Email" },
        { id: 2, text: "Contact" },
      ],
    },
  ];

  useEffect(() => {
    if (reduxLang) {
      if (reduxLang === "lv") {
        setData(dataLatvian);
      } else if (reduxLang === "eng") {
        setData(dataEnglish);
      }
    }
    //eslint-disable-next-line
  }, [reduxLang]);

  return (
    <AnimatedComponent>
      <Wrapper>
        <div className="navigation">
          <h3>{data[0]?.text}</h3>

          <div className="footer-nav">
            {data[1]?.menu?.map((nav) => (
              <Link key={nav?.id} to={nav?.link}>
                {nav?.name}
              </Link>
            ))}
          </div>
        </div>

        <div className="information">
          <img src="../img/logo.png" alt="logo" />

          <p className="description">{data[2]?.text[0]?.text}</p>

          <p className="copyright" style={{textAlign: 'start'}}>{data[2]?.text[1]?.text}</p>
        </div>

        <div className="contacts">
          <h3>{data[3]?.text[2]?.text}</h3>

          <p className="contacts-info">
            {data[3]?.text[0]?.text} +371 25 397 828
          </p>
          <p className="contacts-info">
            {data[3]?.text[1]?.text} info@jaaigroup.com
          </p>

          <div className="contacts-btns">
            <a href="https://wa.me/37125397828">
              <div className="contacts-btns-btn slide">
                <Whatsapp className="icon" />
              </div>
            </a>

            <a href="mailto:info@jaaigroup.com">
              <div className="contacts-btns-btn slide">
                <Mail className="icon" />
              </div>
            </a>
          </div>
        </div>
      </Wrapper>
    </AnimatedComponent>
  );
};

const Wrapper = styled.div`
  max-width: 1200px;
  margin: 40px auto 0;
  padding: 0 20px 50px;
  display: flex;
  gap: 20px;
  justify-content: space-between;

  @media only screen and (max-width: 660px) {
    flex-direction: column;
    gap: 40px;

    .information {
      border-left: none !important;
      border-right: none !important;
      align-items: start !important;
      padding: 0 !important;

      .description {
        text-align: start !important;
      }
    }

    .information,
    .description,
    .contacts {
      max-width: 100% !important;
      width: 100%;
    }
  }

  > * {
    flex: 1 0 0;
  }

  h3 {
    font-size: 1.2rem;
    line-height: 1.3;
    margin-bottom: 20px;
    color: #121315;
  }

  .information {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    border-left: 2px solid #dedede;
    border-right: 2px solid #dedede;
    padding: 0 20px;

    img {
      margin-bottom: 20px;
      width: 30%;
      min-width: 120px;
      max-width: 140px;
    }

    .description,
    .copyright {
      color: #4d4d4d;
      font-size: 0.9rem;
      text-align: center;
      max-width: 350px;
    }

    .description {
      margin-bottom: 20px;
    }
  }

  .contacts,
  .navigation {
    max-width: 250px;
  }

  .contacts {
    .contacts-info {
      font-size: 0.9rem;
      color: #121315;
      margin-bottom: 10px;
    }

    .contacts-btns {
      margin: 20px 0 0 0;
      display: flex;
      gap: 15px;

      .icon {
        width: 24px;
        height: 24px;
      }

      a {
        text-decoration: none;
        color: white;

        .contacts-btns-btn {
          background: #121315;
          padding: 6px;
          border-radius: 10px;
          transition: 0.4s ease-in-out;
        }

        .slide:hover,
        .slide:focus {
          box-shadow: inset 3rem 0 0 0 #376058;
        }
      }
    }
  }

  .navigation {
    .footer-nav {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      a {
        text-decoration: none;
        color: #121315;
        font-size: 0.9rem;
        transition: 0.2s ease-in-out;
      }

      a:hover {
        opacity: 0.6;
      }
    }
  }
`;

export default Footer;
