import React, { useEffect, useState } from "react";
import styled from 'styled-components'

//redux
import { useSelector } from 'react-redux';
import { selectLanguage } from '../redux/variables';

const CookiesAlert = () => {

    //redux
    const reduxLang = useSelector(selectLanguage);

    const [alert, showAlert] = useState('');

    const allowCookies = () => {
        window.localStorage.setItem('cookies-allowed', true);
        showAlert(false);
    }

    const [data, setData] = useState([]);

    const dataLatvian = [
        {id: 0,
        text: 'Lūdzu, ņemiet vērā, ka šajā mājaslapā tiek izmantotas sīkdatnes. Turpinot pārlūkošanu, jūs piekrītat nepieciešamajām sīkdatnēm.'},
        {id: 1,
        text: 'Es sapratu'}
    ]

    const dataEnglish = [
        {id: 0,
        text: 'Please note that cookies are used on this website. By continuing to browse, you consent to the necessary cookies.'},
        {id: 1,
        text: 'I understood'}
    ]

    useEffect(() => {
        if(reduxLang){
            if(reduxLang === 'lv'){ 
              setData(dataLatvian);
            } else if(reduxLang === 'eng'){
              setData(dataEnglish);
            }
        }
        //eslint-disable-next-line
      }, [reduxLang]);

    useEffect(() => {
        const cookiesCheck = window.localStorage.getItem('cookies-allowed');

        if(!cookiesCheck){
            showAlert(true);
        } else {
            showAlert(false);
        }
    }, []);

  return <Wrapper>
    {alert && 
        <div className='cookies-alert'>
            <p>{data[0]?.text}</p>

            <div onClick={() => allowCookies()} className='contact-btn'>
                <p>{data[1]?.text}</p>
            </div>
        </div>
    }
</Wrapper>;
};

const Wrapper = styled.div`

.cookies-alert {
    max-width: 1200px;
    padding: 27px 15px;
    margin: 0 auto;
    position: fixed;
    bottom: 20px;
    right: 10px;
    background: #376058;
    max-width: 300px;
    gap: 10px;

    p {
        font-size: .8rem;
        color: white;
    }

    .contact-btn {
        margin: 20px 0 0 0;
        display: flex;
        justify-content: right;
        cursor: pointer;
        transition: .4s ease-in-out;

        p {
            color: #121315;
            font-size: .8rem;
            background: white;
            padding: 12px 20px;
            font-weight: 600;
            transition: .4s ease-in-out;
            border-radius: 10px;

        }
    }

    .contact-btn:hover {

        p {
            color: white;
            background: #121315;
        }
    }
}
`

export default CookiesAlert;
