import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

//icons
import { CloseOutline } from "@styled-icons/evaicons-outline/CloseOutline";

const ChatBot = () => {
  const [slide, setSlide] = useState(0);

  //slide 0 - default
  //slide 1 - show available options, toggle the list higher and the toggle button aswell.
  //slide 2 - prefer user to use contact form
  //slide 3 - prefer user to message via whatsapp

  const variants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <Wrapper>
      <motion.div
        className="chatbot"
        initial="hidden"
        animate="visible"
        variants={variants}
        exit="hidden"
      >
        <motion.div
          className="chatbot-box"
          onClick={() => {
            setSlide(1);
          }}
          style={{ top: slide > 0 ? "-160px" : "0" }}
          whileHover={{ scale: 1.1 }}
        >
          <img src="./img/mona-img.png" alt="chatbox-img" />
        </motion.div>

        {slide > 0 && (
          <>
            <CloseOutline className="close-icon" onClick={() => setSlide(0)} />
          </>
        )}

        <div
          className="chatbox-textbox"
          style={{
            opacity: slide > 0 ? 1 : 0,
            top:
              slide === 0
                ? "8px"
                : slide === 1
                ? "-320px"
                : slide === 2
                ? "-170px"
                : slide === 3 && "-180px",
          }}
        >
          {slide === 0 && (
            <>
              <p>Vuff vuff, mani sauc Mona! Kā es varu palīdzēt?</p>
            </>
          )}

          {slide === 1 && (
            <>
              <div className="chatbox-options">
                <p
                  className="chatbox-options-option"
                  onClick={() => setSlide(2)}
                >
                  Vēlos izveidot jaunu mājaslapu savam uzņēmējdarbības projektam
                </p>

                <p
                  className="chatbox-options-option"
                  onClick={() => setSlide(2)}
                >
                  Vēlos uzzināt par e-komercijas risinājumiem un tiešsaistes
                  veikalu izveidi
                </p>

                <p
                  className="chatbox-options-option"
                  onClick={() => setSlide(3)}
                >
                  Vēlos iegūt padomus par SEO un mārketingu mājaslapai
                </p>

                <p
                  className="chatbox-options-option"
                  onClick={() => setSlide(3)}
                >
                  Vēlos uzzināt cenas par individuāliem web risinājumiem
                </p>

                <p
                  className="chatbox-options-option"
                  onClick={() => setSlide(2)}
                >
                  Vēlos uzlabot savu esošo mājaslapu
                </p>

                <p
                  className="chatbox-options-option"
                  onClick={() => setSlide(3)}
                >
                  Vēlos bezmaksas konsultāciju, ieteikumus projektam
                </p>

                <p
                  className="chatbox-options-option"
                  onClick={() => setSlide(2)}
                >
                  Vēlos veikt pasūtījumu
                </p>
              </div>
            </>
          )}

          {slide === 2 && (
            <div className="chatbox-options">
              <p>
                Lai iesniegtu detalizētāku ziņu un saņemtu visaptverošu atbildi
                uz jūsu jautājumu, iesaku izmantot mūsu{" "}
                <Link to="/contact">kontaktformu.</Link> Vuff!
              </p>
            </div>
          )}

          {slide === 3 && (
            <>
              <div className="chatbox-options">
                <p>
                  Ja vēlies saņemt atbildi pēc iespējas ātrāk, zvani uz mūsu
                  norādīto kontaktnumuru vai raksti ziņu{" "}
                  <Link to="https://wa.me/37125397828">WhatsApp</Link>. Vuff!
                </p>
              </div>
            </>
          )}
        </div>
      </motion.div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  bottom: 30px;
  right: 30px;

  @media screen and (max-width: 760px) {
    display: none;
  }

  .chatbot {
    position: relative;

    .close-icon {
      max-width: 24px;
      width: 100%;
      color: black;
      position: absolute;
      top: -80px;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
      transition: 0.3s ease-in-out;
    }

    .close-icon:hover {
      color: #376058;
    }

    .chatbot-box {
      position: relative;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      background: #efefef;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s ease-in-out;
      cursor: pointer;

      img {
        width: 45px;
        height: 45px;
        object-fit: contain;
      }
    }

    .chatbot-box:hover {
      background: #cba68a;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }

    .chatbot-box:hover ~ .chatbox-textbox {
      opacity: 1 !important;
    }

    .chatbox-textbox {
      position: absolute;
      width: 300px;
      top: 8px;
      right: 90px;
      padding: 10px;
      background: #efefef;
      border-radius: 5px;
      transition: 0.3s ease-in-out;
      cursor: default;
      opacity: 0;

      p {
        font-size: 0.9rem;
        color: #4d4d4d;
        line-height: 1.4;
      }

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        right: -8px;
        border-width: 8px 0 8px 8px;
        border-style: solid;
        border-color: transparent transparent transparent #efefef;
        transform: translateY(-50%);
        transition: 0.3s ease-in-out;
      }

      .chatbox-options {
        display: flex;
        flex-direction: column;
        gap: 5px;

        a {
          color: #376058;
          text-decoration: underline;
        }

        a:hover {
          text-decoration: none;
        }

        .chatbox-options-option {
          font-size: 0.9rem;
          padding: 6px 9px;
          transition: 0.3s ease-in-out;
          border-radius: 5px;
          cursor: pointer;
        }

        .chatbox-options-option:hover {
          background: #376058;
          color: white;
        }
      }
    }
  }
`;

export default ChatBot;
