import React, { useState, useEffect } from "react";
import styled from "styled-components";

//components
import PageHeading from "../components/PageHeading";
import AnimatedComponent from "../components/AnimatedComponent";

//router
import { Link } from "react-router-dom";

//redux
import { useSelector } from "react-redux";
import { selectLanguage } from "../redux/variables";

//additionals
import { Helmet } from "react-helmet";

const Hosting = () => {
  //redux
  const reduxLang = useSelector(selectLanguage);

  const [pageData, setPageData] = useState({});

  const pageDataLatvian = {
    name: "Hostinga iespējas",
    desc: "Piemērots risinājums jūsu mājaslapas un e-pasta pieejamībai tiešsaistē visu laiku.",
    img: "../img/pages/hosting.jpg",
  };

  const pageDataEnglish = {
    name: "Hosting Options",
    desc: "A suitable solution for your website and email availability online all the time.",
    img: "../img/pages/hosting.jpg",
  };

  const [data, setData] = useState([]);

  const dataLatvian = [
    {
      id: 0,
      name: "WEB hostings",
      desc_top:
        "Mūsu “WEB Hostings” pakalpojums ir piemērots risinājums, lai nodrošinātu, ka jūsu mājaslapa ir pieejama tiešsaistē visu laiku. Mēs piedāvājam drošu un uzticamu hostinga infrastruktūru, kas nodrošina ātru ielādes ātrumu, augstu pieejamību un teicamu lietotāja pieredzi.",
      desc_bottom:
        "Mūsu “WEB Hostings” pakalpojums nodrošina uzticamu pamatu jūsu mājaslapas darbībai tiešsaistē. Ar mums jūs varēsiet izvairīties no tehnikas sarežģījumiem un uzticēties mūsu komandai, lai nodrošinātu, ka jūsu mājaslapa vienmēr ir pieejama un darbojas efektīvi.",
      reasons: [
        {
          id: 0,
          name: "Droša Servera Infrastruktūra. ",
          text: "Jūsu mājaslapa tiks hostēta uz drošiem un uzticamiem serveriem, kas nodrošina datu aizsardzību un drošību.",
        },
        {
          id: 1,
          name: "Ātra Ielāde Un Veiktspēja. ",
          text: "Mēs pievēršam uzmanību serveru veiktspējai, nodrošinot, ka jūsu mājaslapa ielādējas ātri un sniedz lietotājiem labu un gludu pieredzi.",
        },
        {
          id: 2,
          name: "Drošība Un Atbalsts. ",
          text: "Mēs uzraudzīsim serveru drošību un nodrošināsim nepārtrauktu atbalstu, lai jūs varētu koncentrēties uz satura izstrādi un pārvaldību.",
        },
      ],
      price: 5,
      price_tag: "Cena mēnesī",
      order_btn: "Pasūtīt",
      currency: "eiro",
    },
    {
      id: 1,
      name: "E-pasta hostings",
      desc_top:
        "Mūsu “E-pasta Hostings” piedāvā jums profesionālu e-pasta risinājumu ar jūsu pašu domēna nosaukumu. Šāds e-pasts rada uzticību un atpazīstamību, uzlabojot jūsu uzņēmuma tēlu. Mēs nodrošinām drošu un viegli pārvaldāmu e-pasta platformu, kas atbilst jūsu vajadzībām komunikācijā un sadarbībā.",
      price: 5,
      price_tag: "Cena mēnesī",
      order_btn: "Pasūtīt",
      currency: "eiro",
    },
  ];

  const dataEnglish = [
    {
      id: 0,
      name: "WEB Hosting",
      desc_top:
        'Our "WEB Hosting" service is a suitable solution to ensure your website is available online all the time. We offer secure and reliable hosting infrastructure that provides fast loading speed, high availability, and excellent user experience.',
      desc_bottom:
        'Our "WEB Hosting" service provides a reliable foundation for your websites online operation. With us, you can avoid technical complexities and rely on our team to ensure your website is always accessible and functions effectively.',
      reasons: [
        {
          id: 0,
          name: "Secure Server Infrastructure.",
          text: "Your website will be hosted on secure and reliable servers, ensuring data protection and security.",
        },
        {
          id: 1,
          name: "Fast Loading and Performance.",
          text: "We pay attention to server performance, ensuring your website loads quickly and provides users with a good and smooth experience.",
        },
        {
          id: 2,
          name: "Security and Support.",
          text: "We will monitor server security and provide continuous support, allowing you to focus on content development and management.",
        },
      ],
      price: 5,
      price_tag: "Price per month",
      order_btn: "Order",
      currency: "euros",
    },
    {
      id: 1,
      name: "Email Hosting",
      desc_top:
        'Our "Email Hosting" offers you a professional email solution with your own domain name. Such an email enhances trust and recognition, improving your company\'s image. We provide a secure and easy-to-manage email platform that meets your communication and collaboration needs.',
      price: 5,
      price_tag: "Price per month",
      order_btn: "Order",
      currency: "euros",
    },
  ];

  useEffect(() => {
    if (reduxLang) {
      if (reduxLang === "lv") {
        setPageData(pageDataLatvian);
        setData(dataLatvian);
      } else if (reduxLang === "eng") {
        setPageData(pageDataEnglish);
        setData(dataEnglish);
      }
    }
    //eslint-disable-next-line
  }, [reduxLang]);

  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>JAAIGROUP: Hostinga Risinājumi</title>
        <link rel="canonical" href="http://jaaigroup.com/" />
        <meta
          property="og:title"
          content="JAAIGROUP: Hostinga Iespējas un Uzturēšana"
        />
        <meta name="author" content="JAAIGROUP" />
        <meta
          name="description"
          content="Uzziniet par mūsu hostinga iespējām, kas nodrošina stabilitāti, drošību un ātru veiktspēju jūsu mājas lapai. Mēs piedāvājam dažādus hostinga plānus, kas pielāgojas jūsu vajadzībām, ieskaitot mājas lapu hostingu, datubāzu pārvaldību un SSL sertifikātus. Mūsu mērķis ir nodrošināt uzņēmumiem, organizācijām un privātpersonām uzticamus hostinga risinājumus."
        />
        <meta
          property="og:description"
          content="Uzziniet par mūsu hostinga iespējām, kas nodrošina stabilitāti, drošību un ātru veiktspēju jūsu mājas lapai. Mēs piedāvājam dažādus hostinga plānus, kas pielāgojas jūsu vajadzībām, ieskaitot mājas lapu hostingu, datubāzu pārvaldību un SSL sertifikātus. Mūsu mērķis ir nodrošināt uzņēmumiem, organizācijām un privātpersonām uzticamus hostinga risinājumus."
        />
        <meta property="og:locale" content="lv_LV" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="http://jaaigroup.com/" />
        <meta
          property="og:site_name"
          content="JAAIGROUP: Hostinga Iespējas un Uzturēšana"
        />
        <meta
          name="twitter:description"
          content="Hostinga risinājumi, kas nodrošina jūsu mājas lapas stabilitāti un drošību. Atvieglojiet darbu ar mūsu uzņēmuma uzticamajiem hostinga plāniem."
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content="JAAIGROUP: Hostinga Iespējas un Uzturēšana"
        />
        <meta
          name="keywords"
          content="Hostinga iespējas, hostinga risinājumi, mājas lapu hosting, datubāzu pārvaldība, SSL sertifikāti, veiktspēja, drošība, uzticams hostinga pakalpojums, mājaslapas uzturēšana, uzņēmumiem, organizācijām, privātpersonām, hostinga plāni"
        />
      </Helmet>

      <AnimatedComponent>
        <PageHeading data={pageData} />

        <div className="hosting-content">
          {data?.map((item) => (
            <div className="list-item" key={item?.id}>
              <div className="list-item-left">
                <h3>{item?.name}</h3>

                <div className="list-item-pricing-mobile">
                  <div className="list-item-pricing-mobile-pricing">
                    <p className="pricing-tag">{item?.price_tag}</p>
                    <p className="pricing">
                      <span style={{ fontSize: "1.3rem", color: "#376058" }}>
                        {item?.price},-
                      </span>{" "}
                      {item?.currency}
                    </p>
                  </div>

                  <Link to="/contact/hosting">
                    <div className="btn slide">{item?.order_btn}</div>
                  </Link>
                </div>

                <p className="desc-top">{item?.desc_top}</p>

                <div className="reason-list">
                  {item?.reasons &&
                    item?.reasons.map((reason) => (
                      <p key={reason?.id} className="reason-list-item">
                        <b>{reason?.name}</b> {reason?.text}
                      </p>
                    ))}
                </div>

                {item?.desc_bottom && (
                  <p className="desc-bottom">{item?.desc_bottom}</p>
                )}
              </div>

              <div className="list-item-right">
                <p className="pricing-tag">{item?.price_tag}</p>
                <p className="pricing">
                  <span style={{ fontSize: "1.3rem", color: "#376058" }}>
                    {item?.price},-
                  </span>{" "}
                  {item?.currency}
                </p>
                <Link to="/contact/hosting">
                  <div className="btn slide">{item?.order_btn}</div>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </AnimatedComponent>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .list-item {
    display: flex;
    gap: 50px;
    justify-content: space-between;
    margin: 0 0 30px 0;
    padding: 0 20px 30px;
    border-bottom: 2px solid #dedede;

    .list-item-pricing-mobile {
      display: none;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      @media only screen and (max-width: 660px) {
        display: flex;
      }

      .pricing-tag {
        font-size: 0.9rem;
        color: #4d4d4d;
        min-width: 90px;
        text-align: end !important;
      }

      .pricing {
        font-size: 0.8rem;
        color: #121315;
      }

      .slide:hover,
      .slide:focus {
        box-shadow: inset 10rem 0 0 0 #121315;
      }

      .btn {
        background: #376058;
        color: white;
        text-decoration: none;
        display: inline-block;
        padding: 12px 20px;
        font-size: 0.8rem;
        border-radius: 10px;
        transition: 0.4s ease-in-out;
      }
    }

    .list-item-right {
      display: flex;
      flex-direction: column;
      align-items: end;
      gap: 5px;

      @media only screen and (max-width: 660px) {
        display: none;
      }

      .pricing-tag {
        font-size: 0.9rem;
        color: #4d4d4d;
        min-width: 90px;
        text-align: end !important;
      }

      .pricing {
        font-size: 0.8rem;
        color: #121315;
      }

      .slide:hover,
      .slide:focus {
        box-shadow: inset 10rem 0 0 0 #121315;
      }

      .btn {
        background: #376058;
        color: white;
        text-decoration: none;
        display: inline-block;
        padding: 12px 20px;
        font-size: 0.8rem;
        border-radius: 10px;
        transition: 0.4s ease-in-out;
        margin: 10px 0 0 0;
      }
    }

    .list-item-left {
      max-width: 700px;

      h3 {
        font-size: 1.4rem;
        color: #121315;
        margin: 0 0 20px 0;
      }

      .desc-top,
      .desc-bottom {
        font-size: 0.9rem;
        color: #121315;
        margin: 0 0 10px 0;
        line-height: 1.3;
      }

      .reason-list {
        margin: 0 0 10px 0;

        .reason-list-item {
          font-size: 0.9rem;
          color: #121315;
          margin: 0 0 10px 0;
          line-height: 1.3;
        }
      }
    }
  }

  .hosting-content {
    max-width: 1200px;
    margin: 80px auto;
    padding: 0 20px;

    @media only screen and (max-width: 660px) {
      margin: 0 0 60px 0;
    }
  }
`;

export default Hosting;
