import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  lang: null,
};


export const variables = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.lang = action.payload;
    },
  },
});

export const { setLanguage } = variables.actions;
export const selectLanguage = (state) => state.app.lang;

export default variables.reducer;