import React, { useState, useEffect } from "react";
import styled from "styled-components";

//components
import PageHeading from "../components/PageHeading";
import AnimatedComponent from "../components/AnimatedComponent";

//router
import { Link } from "react-router-dom";

//redux
import { useSelector } from "react-redux";
import { selectLanguage } from "../redux/variables";

//additionals
import { Helmet } from "react-helmet";

const Pricing = () => {
  //redux
  const reduxLang = useSelector(selectLanguage);

  const [pageData, setPageData] = useState({});

  const pageDataLatvian = {
    name: "Pakalpojumu izmaksas",
    desc: "Mūsu pakalpojumu izcenojums ir atkarīgs no projekta apjoma un izstrādes laika.",
    img: "../img/pages/pricing.jpg",
  };

  const pageDataEnglish = {
    name: "Service Costs",
    desc: "The cost of our services depends on the scope of the project and development time.",
    img: "../img/pages/pricing.jpg",
  };

  const [data, setData] = useState([]);

  const dataLatvian = [
    {
      id: 0,
      name: "Mājas lapu izstrāde",
      desc: "Nodrošināt jums mājaslapu, kas ne tikai izceļ jūsu uzņēmumu, bet arī palīdz jums sasniegt savus digitālos mērķus. Ar mums jūs varat būt droši, ka jūsu mājaslapa būs saderīga ar mūsdienīgajām prasībām un atbilstoša jūsu uzņēmuma vērtībām.",
      link: "/services/web",
      price: 149,
      price_tag: "Sākot no",
      currency: "eiro",
      btn_text: "Vairāk informācija",
    },
    {
      id: 1,
      name: "Internetveikalu izstrāde",
      desc: "Jūs varēsiet ērti uzsākt un vadīt savu e-komercijas biznesu, nemazinoties ar tehniskiem aspektiem. Mēs uzņemsimies gandrīz visu smagāko darbu, lai jūs varētu koncentrēties uz pārdošanu un jūsu uzņēmuma attīstību. Mūsu mērķis ir nodrošināt, ka jūsu interneta veikals ir pievilcīgs, efektīvs un veicina jūsu biznesa izaugsmi.",
      link: "/services/ecommerce",
      price: 699,
      price_tag: "Sākot no",
      currency: "eiro",
      btn_text: "Vairāk informācija",
    },
    {
      id: 2,
      name: "SEO optimizācija",
      desc: "Sniedz jums iespēju iegūt organiskas redzamības priekšrocības, piesaistot vairāk kvalitatīvu apmeklētāju un potenciālo klientu. Ar mums jūs varēsiet palielināt savu klātbūtni tiešsaistē un sasniegt lielāku auditoriju, kas meklē tieši to, ko jūs piedāvājat.",
      link: "/services/seo",
      price: 49,
      price_tag: "Sākot no",
      currency: "eiro",
      btn_text: "Vairāk informācija",
    },
    {
      id: 3,
      name: "Grafiskais dizains",
      desc: "Dot jums radošu atbalstu, padarot jūsu vizuālo identitāti piesaistošu un atmiņā paliekošu. Neatkarīgi no tā, vai jums nepieciešams atsvaidzināt esošo dizainu, vai arī jūs meklējat jaunas idejas, mēs esam šeit, lai palīdzētu jums izcelties un piesaistīt jūsu mērķauditoriju.",
      link: "/services/design",
      price: 99,
      price_tag: "Sākot no",
      currency: "eiro",
      btn_text: "Vairāk informācija",
    },
    {
      id: 4,
      name: "Satura vadības sistēma",
      desc: "Jūsu digitālais administratora panelis, kas ne tikai palīdz uzturēt mājaslapas saturu, bet arī piedāvā praktiskas un dinamiskas funkcijas, kas padara jūsu ikdienu efektīvāku. Jums nav jābūt tehniskam ekspertam, lai izmantotu šo spēcīgo rīku jūsu uzņēmuma labā.",
      link: "/services/cms",
      price: 299,
      price_tag: "Sākot no",
      currency: "eiro",
      btn_text: "Vairāk informācija",
    },
    {
      id: 5,
      name: "Mobilo aplikāciju izveide",
      desc: "Viedtelefoniem un planšetdatoriem. Tāpat kā web aplikācijas, mobilās aplikācijas nodrošina lietotājiem dažādus pakalpojumus un funkcijas, taču tās ir izstrādātas, ņemot vērā mobilās ierīces specifiku un ierobežojumus.",
      link: "/services/mobile",
      price: 399,
      price_tag: "Sākot no",
      currency: "eiro",
      btn_text: "Vairāk informācija",
    },
    {
      id: 6,
      name: "Personalizētas WEB sistēmas",
      desc: "Jūsu iespēja izveidot un pielāgot web risinājumu, kas tieši atbilst jūsu biznesa vajadzībām. Mēs nodrošinām, ka jūsu sistēma ir unikāla, efektīva un sniedz jums pārskatus, vadības instrumentus vai citas funkcijas, kas palīdzēs jums uzlabot darbību un efektivitāti.",
      link: "/services/web-systems",
      price: 499,
      price_tag: "Sākot no",
      currency: "eiro",
      btn_text: "Vairāk informācija",
    },
  ];

  const dataEnglish = [
    {
      id: 0,
      name: "Website Development",
      desc: "Provide you with a website that not only showcases your business but also helps you achieve your digital goals. With us, you can be confident that your website will be compatible with modern requirements and align with your company values.",
      link: "/services/web",
      price: 149,
      price_tag: "Starting from",
      currency: "euros",
      btn_text: "More Information",
    },
    {
      id: 1,
      name: "E-commerce Development",
      desc: "You will be able to easily start and manage your e-commerce business without dealing with technical aspects. We will take care of almost all the heavy lifting, allowing you to focus on sales and the growth of your business. Our goal is to ensure your online store is attractive, effective, and contributes to your business growth.",
      link: "/services/ecommerce",
      price: 699,
      price_tag: "Starting from",
      currency: "euros",
      btn_text: "More Information",
    },
    {
      id: 2,
      name: "SEO Optimization",
      desc: "Offers you the opportunity to gain the benefits of organic visibility by attracting more quality visitors and potential customers. With us, you can increase your online presence and reach a larger audience actively searching for what you offer.",
      link: "/services/seo",
      price: 49,
      price_tag: "Starting from",
      currency: "euros",
      btn_text: "More Information",
    },
    {
      id: 3,
      name: "Graphic Design",
      desc: "Provide you with creative support, making your visual identity attractive and memorable. Whether you need to refresh your existing design or are looking for new ideas, we are here to help you stand out and engage your target audience.",
      link: "/services/design",
      price: 99,
      price_tag: "Starting from",
      currency: "euros",
      btn_text: "More Information",
    },
    {
      id: 4,
      name: "Content Management System",
      desc: "Your digital admin panel that not only helps maintain your website content but also offers practical and dynamic features to make your daily tasks more efficient. You don’t need to be a technical expert to utilize this powerful tool for the benefit of your company.",
      link: "/services/cms",
      price: 299,
      price_tag: "Starting from",
      currency: "euros",
      btn_text: "More Information",
    },
    {
      id: 5,
      name: "Mobile App Development",
      desc: "For smartphones and tablets. Similar to web applications, mobile apps provide users with various services and features, but they are developed with the specificities and limitations of mobile devices in mind.",
      link: "/services/mobile",
      price: 399,
      price_tag: "Starting from",
      currency: "euros",
      btn_text: "More Information",
    },
    {
      id: 6,
      name: "Custom Web Systems",
      desc: "Your opportunity to create and customize a web solution that directly aligns with your business needs. We ensure your system is unique, efficient, and provides you with insights, management tools, or other functionalities to help improve your operations and effectiveness.",
      link: "/services/web-systems",
      price: 499,
      price_tag: "Starting from",
      currency: "euros",
      btn_text: "More Information",
    },
  ];

  useEffect(() => {
    if (reduxLang) {
      if (reduxLang === "lv") {
        setPageData(pageDataLatvian);
        setData(dataLatvian);
      } else if (reduxLang === "eng") {
        setPageData(pageDataEnglish);
        setData(dataEnglish);
      }
    }
    //eslint-disable-next-line
  }, [reduxLang]);

  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>JAAIGROUP: Servisu Cenas</title>
        <link rel="canonical" href="http://jaaigroup.com/" />
        <meta
          property="og:title"
          content="JAAIGROUP: Servisu Cenas un Pakalpojumi"
        />
        <meta name="author" content="JAAIGROUP" />
        <meta
          name="description"
          content="Iepazīstieties ar mūsu piedāvāto servisu cenām un plašo pakalpojumu klāstu. Mēs piedāvājam visaptverošus risinājumus, ieskaitot mājas lapu dizainu un izstrādi, hostingu un uzturēšanu, SEO optimizāciju, e-komercijas risinājumus un sociālo mediju integrāciju. Neatkarīgi no jūsu vajadzībām, mēs strādājam, lai palīdzētu sasniegt jūsu biznesa mērķus un atbilstošu budžetu."
        />
        <meta
          property="og:description"
          content="Iepazīstieties ar mūsu piedāvāto servisu cenām un plašo pakalpojumu klāstu. Mēs piedāvājam visaptverošus risinājumus, ieskaitot mājas lapu dizainu un izstrādi, hostingu un uzturēšanu, SEO optimizāciju, e-komercijas risinājumus un sociālo mediju integrāciju. Neatkarīgi no jūsu vajadzībām, mēs strādājam, lai palīdzētu sasniegt jūsu biznesa mērķus un atbilstošu budžetu."
        />
        <meta property="og:locale" content="lv_LV" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="http://jaaigroup.com/" />
        <meta
          property="og:site_name"
          content="JAAIGROUP: Servisu Cenas un Pakalpojumi"
        />
        <meta
          name="twitter:description"
          content="Iepazīstieties ar mūsu piedāvāto servisu cenām un plašo pakalpojumu klāstu. Palīdzam jums veidot efektīvu digitālo klātbūtni, kas atbilst jūsu biznesa mērķiem."
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content="JAAIGROUP: Servisu Cenas un Pakalpojumi"
        />
        <meta
          name="keywords"
          content="Servisu cenas, piedāvātie pakalpojumi, mājaslapu dizains, mājaslapu izstrāde, SEO optimizācija, e-komercija, sociālo mediju integrācija, digitālais mārketings, pakalpojumu klāsts, cenu saraksts, uzņēmumu servisu, organizāciju risinājumi, budžeta pielāgojums, efektīva klātbūtne"
        />
      </Helmet>

      <AnimatedComponent>
        <PageHeading data={pageData} />

        <div className="pricing-content">
          {data?.map((item) => (
            <div className="list-item" key={item?.id}>
              <div className="list-item-left">
                <h3>{item?.name}</h3>

                <div className="list-item-pricing-mobile">
                  <div className="list-item-pricing-mobile-pricing">
                    <p className="pricing-tag">{item?.price_tag}</p>
                    <p className="pricing">
                      <span style={{ fontSize: "1.3rem", color: "#376058" }}>
                        {item?.price},-
                      </span>{" "}
                      {item?.currency}
                    </p>
                  </div>

                  <Link to={item?.link}>
                    <div className="btn slide">{item?.btn_text}</div>
                  </Link>
                </div>

                <p className="desc">{item?.desc}</p>
              </div>

              <div className="list-item-right">
                <p className="pricing-tag">{item?.price_tag}</p>
                <p className="pricing">
                  <span style={{ fontSize: "1.3rem", color: "#376058" }}>
                    {item?.price},-
                  </span>{" "}
                  {item?.currency}
                </p>
                <Link to={item?.link}>
                  <div className="btn slide">{item?.btn_text}</div>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </AnimatedComponent>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .list-item {
    display: flex;
    gap: 50px;
    justify-content: space-between;
    margin: 0 0 30px 0;
    padding: 0 20px 30px;
    border-bottom: 2px solid #dedede;

    .list-item-pricing-mobile {
      display: none;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      @media only screen and (max-width: 660px) {
        display: flex;
      }

      .pricing-tag {
        font-size: 0.9rem;
        color: #4d4d4d;
        min-width: 90px;
      }

      .pricing {
        font-size: 0.8rem;
        color: #121315;
      }

      .slide:hover,
      .slide:focus {
        box-shadow: inset 10rem 0 0 0 #121315;
      }

      .btn {
        background: #376058;
        color: white;
        text-decoration: none;
        display: inline-block;
        padding: 12px 20px;
        font-size: 0.8rem;
        border-radius: 10px;
        transition: 0.4s ease-in-out;
      }
    }

    .list-item-right {
      display: flex;
      flex-direction: column;
      align-items: end;
      gap: 5px;

      @media only screen and (max-width: 660px) {
        display: none;
      }

      .pricing-tag {
        font-size: 0.9rem;
        color: #4d4d4d;
      }

      .pricing {
        font-size: 0.8rem;
        color: #121315;
      }

      .slide:hover,
      .slide:focus {
        box-shadow: inset 10rem 0 0 0 #121315;
      }

      .btn {
        background: #376058;
        color: white;
        text-decoration: none;
        display: inline-block;
        padding: 12px 20px;
        font-size: 0.8rem;
        border-radius: 10px;
        transition: 0.4s ease-in-out;
        margin: 10px 0 0 0;
        min-width: 152px;
        text-align: center;
      }
    }

    .list-item-left {
      max-width: 700px;

      h3 {
        font-size: 1.4rem;
        color: #121315;
        margin: 0 0 20px 0;
      }

      .desc {
        font-size: 0.9rem;
        color: #121315;
        margin: 0 0 10px 0;
        line-height: 1.3;
      }
    }
  }

  .pricing-content {
    max-width: 1200px;
    margin: 80px auto;
    padding: 0 20px;
  }
`;

export default Pricing;
