//router
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

//pages
import Home from "./pages/Home";
import About from "./pages/About";
import Hosting from "./pages/Hosting";
import Pricing from "./pages/Pricing";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import PageNotFound from "./pages/PageNotFound";
import PageLanguage from "./pages/PageLanguage";

//components
import Header from "./components/Header";
import Footer from "./components/Footer";
import CookiesAlert from "./components/CookiesAlert";
import ChatBot from "./components/ChatBot";

//redux
import { Provider } from "react-redux";
import { store } from "./redux/store";

//additionals
import { Helmet } from "react-helmet";

//google
import ReactGA from "react-ga4";
ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_ANALYTICS}`);

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <div className="App">
          <Helmet>
            <meta charSet="utf-8" />
            <title>JAAIGROUP: Mājaslapu Izstrāde un Apkalpošana</title>
            <link rel="canonical" href="http://jaaigroup.com/" />
            <meta
              property="og:title"
              content="JAAIGROUP: Mājaslapu Izstrāde un Apkalpošana"
            />
            <meta name="author" content="JAAIGROUP" />
            <meta
              name="description"
              content="Mūsu mājas lapu izstrādes pakalpojumi palīdzēs jums izveidot profesionālu un efektīvu mājas lapu, kas atbilst jūsu biznesa mērķiem. Mēs piedāvājam plašu klāstu pakalpojumu, ieskaitot mājas lapu dizainu un izstrādi, mājas lapu hostingu un uzturēšanu, SEO optimizāciju, e-komercijas risinājumus un sociālo mediju integrāciju. Mēs sadarbojamies ar dažādiem klientiem, tostarp uzņēmumiem, organizācijām un privātpersonām. Mūsu mērķis ir palīdzēt jums sasniegt biznesa rezultātus, izstrādājot mājas lapu, kas pielāgota jūsu vajadzībām un budžetam."
            />
            <meta
              property="og:description"
              content="Mūsu mājas lapu izstrādes pakalpojumi palīdzēs jums izveidot profesionālu un efektīvu mājas lapu, kas atbilst jūsu biznesa mērķiem. Mēs piedāvājam plašu klāstu pakalpojumu, ieskaitot mājas lapu dizainu un izstrādi, mājas lapu hostingu un uzturēšanu, SEO optimizāciju, e-komercijas risinājumus un sociālo mediju integrāciju. Mēs sadarbojamies ar dažādiem klientiem, tostarp uzņēmumiem, organizācijām un privātpersonām. Mūsu mērķis ir palīdzēt jums sasniegt biznesa rezultātus, izstrādājot mājas lapu, kas pielāgota jūsu vajadzībām un budžetam."
            />
            <meta property="og:locale" content="lv_LV" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="http://jaaigroup.com/" />
            <meta
              property="og:site_name"
              content="JAAIGROUP: Mājaslapu Izstrāde un Apkalpošana"
            />
            <meta
              name="twitter:description"
              content="Mājas lapu izstrāde jūsu biznesam vai organizācijai. Mēs radām digitālus risinājumus, kas realizē jūsu redzējumu dzīvē."
            />
            <meta name="twitter:card" content="summary" />
            <meta
              name="twitter:title"
              content="JAAIGROUP: Mājaslapu Izstrāde un Apkalpošana"
            />
            <meta
              name="keywords"
              content="Lietotāja pieredze (UX), Lietotāja interfeiss (UI), Web dizains, Web izstrāde, Mājaslapas izveide, Frontend izstrāde, Backend izstrāde, Reaģējošs dizains, Mobilā optimizācija, E-komercijas izstrāde, Pielāgota tīmekļa lietotne, SEO Optimizācija, Mājaslapu pakalpojumi, Mājaslapu izstrādes uzņēmums, Mājaslapu izstrādes portfolio, Mājaslapu izstrādes process, Mājaslapu izstrādes cena, Tiešsaistes veikals, Bizness, Landing lapa, Moderns dizains, Tīmekļa izveide, Tīmekļa dizains, Reaģējošs tīmeklis, Mājaslapas izveidošana, Mājaslapu izstrādes pakalpojumi, Angļu valoda, Digitālais mārketings, Tiešsaistes klātbūtne, Mājaslapu uzturēšana, SEO analīze, SEO optimizācija, Tīmekļa tehnoloģijas, Lapas izstrāde, Dizaina izveide, Programmatūras izstrāde, Tiešsaistes veikals, Mājaslapas atjaunināšana, Interaktīva mājaslapa, Mobilās lietotnes izstrāde, Satura izveide, Lietotāju datu aizsardzība, Mājaslapu testēšana, Mājaslapu drošība, Klientu atbalsts, mājaslapu izstrāde,
          mājaslapas izveide,
          mājaslapas dizains,
          mājaslapu izstrādes pakalpojumi,
          mājaslapu hostings,
          mājaslapu uzturēšana,
          SEO optimizācija,
          e-komercija,
          sociālo mediju integrācija,
          mājaslapu izstrāde uzņēmumiem,
          mājaslapu izstrāde organizācijām,
          mājaslapu izstrāde privātpersonām"
            />
          </Helmet>

          <Header />

          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/about" exact element={<About />} />
            <Route path="/hosting" exact element={<Hosting />} />
            <Route path="/pricing" exact element={<Pricing />} />
            <Route path="/services" exact element={<Services />} />
            <Route path="/services/:id" exact element={<Services />} />
            <Route path="/contact" exact element={<Contact />} />
            <Route path="/contact/:id" exact element={<Contact />} />
            <Route path="/lv" exact element={<PageLanguage />} />
            <Route path="/eng" exact element={<PageLanguage />} />
            <Route path="/404" exact element={<PageNotFound />} />
            <Route path="/*" element={<Navigate to="/404" />} />
          </Routes>

          <ChatBot />
          <CookiesAlert />
          <Footer />
        </div>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
