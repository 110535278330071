import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";

//router
import { Link, useParams } from "react-router-dom";

//components
import PageHeading from "../components/PageHeading";
import AnimatedComponent from "../components/AnimatedComponent";

//icon
import { Whatsapp } from "@styled-icons/bootstrap/Whatsapp";
import { Mail } from "@styled-icons/entypo/Mail";
import { Call } from "@styled-icons/material/Call";
import { PenFill } from "@styled-icons/bootstrap/PenFill";
import { DownArrow } from "@styled-icons/boxicons-solid/DownArrow";
import { UpArrow } from "@styled-icons/boxicons-solid/UpArrow";
import { Checkmark } from "@styled-icons/evaicons-solid/Checkmark";

//redux
import { useSelector } from "react-redux";
import { selectLanguage } from "../redux/variables";

//additionals
import { Helmet } from "react-helmet";

// //emailjs
// import emailjs from "emailjs-com";
// emailjs.init(`${process.env.REACT_APP_EMAILJS_APP_ID}`);

const Contact = () => {
  //router
  const params = useParams();

  //redux
  const reduxLang = useSelector(selectLanguage);

  const [pageData, setPageData] = useState({});

  const pageDataLatvian = {
    name: "Sazinieties ar mums!",
    desc: "Pāstāstiet mums, kā mēs jums varam palīdzēt.",
    img: "../img/pages/contact.jpg",
  };

  const pageDataEnglish = {
    name: "Get in touch!",
    desc: "Tell us more, how can we help you.",
    img: "../img/pages/contact.jpg",
  };

  const [reasonSelection, setReasonSelection] = useState([]);

  const reasonSelectionLatvian = [
    {
      id: 0,
      name: "Bezmaksas konsultācija",
    },
    {
      id: 1,
      name: "Pakalpojuma pasūtīšana",
    },
    {
      id: 2,
      name: "Hostinga pasūtīšana",
    },
    {
      id: 3,
      name: "Atsauksme",
    },
    {
      id: 4,
      name: "Cits",
    },
  ];

  const reasonSelectionEnglish = [
    {
      id: 0,
      name: "Free consultation",
    },
    {
      id: 1,
      name: "Order a service",
    },
    {
      id: 2,
      name: "Hosting solution",
    },
    {
      id: 3,
      name: "Review",
    },
    {
      id: 4,
      name: "Other reason",
    },
  ];

  const [data, setData] = useState([]);

  const dataLatvian = [
    {
      id: 0,
      name: "email-sent-text",
      text: [
        { id: 0, text: "Esam saņēmuši jūsu ziņu" },
        { id: 1, text: "Atkārtotu ziņu varēsiet nosūtīt pēc" },
        { id: 2, text: "minūtēm" },
      ],
    },
    {
      id: 1,
      name: "contact-left",
      text: [
        { id: 0, text: "Izvēlies savu ērtāko saziņas veidu" },
        { id: 1, text: "Tālr. Nr." },
        { id: 2, text: "Saziņas forma" },
      ],
    },
    {
      id: 2,
      name: "contact-right",
      text: [
        { id: 0, text: "Nosūtiet mums ziņu" },
        { id: 1, text: "Vārds*" },
        { id: 2, text: "E-pasts*" },
        { id: 3, text: "Tālr. Nr." },
        { id: 4, text: "Saziņas iemesls" },
        { id: 5, text: "Jūsu ziņa...*" },
        { id: 6, text: "Atlikušās rakstzīmes" },
        { id: 7, text: "Nosūtam ziņu..." },
        { id: 8, text: "Nosūtīt ziņu" },
      ],
    },
  ];

  const dataEnglish = [
    {
      id: 0,
      name: "email-sent-text",
      text: [
        {
          id: 0,
          text: "We have received your message",
        },
        {
          id: 1,
          text: "You can send another message in",
        },
        {
          id: 2,
          text: "minutes",
        },
      ],
    },
    {
      id: 1,
      name: "contact-left",
      text: [
        {
          id: 0,
          text: "Choose your preferred method of contact",
        },
        {
          id: 1,
          text: "Phone Number",
        },
        {
          id: 2,
          text: "Contact Form",
        },
      ],
    },
    {
      id: 2,
      name: "contact-right",
      text: [
        {
          id: 0,
          text: "Send us a message",
        },
        {
          id: 1,
          text: "Name*",
        },
        {
          id: 2,
          text: "Email*",
        },
        {
          id: 3,
          text: "Phone Number",
        },
        {
          id: 4,
          text: "Reason for Contact",
        },
        {
          id: 5,
          text: "Your message...*",
        },
        {
          id: 6,
          text: "Remaining characters",
        },
        {
          id: 7,
          text: "Sending message...",
        },
        {
          id: 8,
          text: "Send message",
        },
      ],
    },
  ];

  useEffect(() => {
    if (reduxLang) {
      if (reduxLang === "lv") {
        setPageData(pageDataLatvian);
        setReasonSelection(reasonSelectionLatvian);
        setReason(reasonSelectionLatvian[0]?.name);
        setData(dataLatvian);
      } else if (reduxLang === "eng") {
        setPageData(pageDataEnglish);
        setReasonSelection(reasonSelectionEnglish);
        setReason(reasonSelectionEnglish[0]?.name);
        setData(dataEnglish);
      }
    }
    //eslint-disable-next-line
  }, [reduxLang]);

  //reason handler
  const [reason, setReason] = useState(reasonSelection[0]?.name);
  const [showReasons, setShowReasons] = useState(false); //reasons dropwdows

  //name handler
  const [name, setName] = useState("");
  const [nameAlert, setNameAlert] = useState("");

  //number handler
  const [number, setNumber] = useState("");

  //email handler
  const [email, setEmail] = useState("");
  const [emailAlert, setEmailAlert] = useState("");

  //message handler
  const [message, setMessage] = useState("");
  const [messageAlert, setMessageAlert] = useState("");
  const [letterCount, setLetterCount] = useState(500); //letter count for the message

  //calculates message length
  useEffect(() => {
    let messageString = message.replace(/\s/g, "");
    setLetterCount((letterCount) => 500 - messageString.length);
  }, [message]);

  //sending handler
  const [isEmailBeingSent, setIsEmailBeingSent] = useState(false);
  const [messageSent, setMessageSent] = useState();
  const storage = window.localStorage;
  const [idle, setIdle] = useState(0);

  //message idle time to be able to send next message
  const idleTime = 10;

  //verify inputs
  const verifyInputs = async () => {
    return new Promise((resolve) => {
      let alerts = 0;

      //name input check
      if (name?.length === 0 || name.trim() === "") {
        if (reduxLang === "lv") {
          setNameAlert("Šī aile nevar būt tukša.");
        } else if (reduxLang === "eng") {
          setNameAlert("This input cannot be empty.");
        }
        alerts = alerts + 1;
      } else if (name?.length < 3) {
        if (reduxLang === "lv") {
          setNameAlert("Ievadi vismaz 3 rakstzīmes.");
        } else if (reduxLang === "eng") {
          setNameAlert("Type at least 3 letters.");
        }
        alerts = alerts + 1;
      } else {
        setNameAlert("");
      }

      //email input check
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (email?.length === 0 || email.trim() === "") {
        if (reduxLang === "lv") {
          setEmailAlert("Šī aile nevar būt tukša.");
        } else if (reduxLang === "eng") {
          setEmailAlert("This input cannot be empty.");
        }
        alerts = alerts + 1;
      } else if (!emailRegex.test(email)) {
        if (reduxLang === "lv") {
          setEmailAlert("Lūdzu ievadi derīgu e-pasta adresi.");
        } else if (reduxLang === "eng") {
          setEmailAlert("Please type valid email address.");
        }
        alerts = alerts + 1;
      } else {
        setEmailAlert("");
      }

      //message input check
      if (message?.length === 0 || message.trim() === "") {
        if (reduxLang === "lv") {
          setMessageAlert("Šī aile nevar būt tukša.");
        } else if (reduxLang === "eng") {
          setMessageAlert("This input cannot be empty.");
        }
        alerts = alerts + 1;
      } else if (message.replace(/\s/g, "").length < 50) {
        if (reduxLang === "lv") {
          setMessageAlert("Lūdzu ievadi vismaz 50 rakstzīmes.");
        } else if (reduxLang === "eng") {
          setMessageAlert("Please type at least 50 letters.");
        }
        alerts = alerts + 1;
      } else {
        setMessageAlert("");
      }

      //check if there is any alerts
      if (alerts > 0) {
        resolve(false);
      } else {
        resolve(true);
      }
    });
  };

  //clearing inputs
  const clearInputs = () => {
    setName("");
    setNumber("");
    if (reduxLang === "lv") {
      setReason(reasonSelectionLatvian[0]?.name);
    } else if (reduxLang === "eng") {
      setReason(reasonSelectionEnglish[0]?.name);
    }
    setNumber("");
    setEmail("");
    setMessage("");
  };

  const sendMessageFunction = async (formData) => {
    try {
      setIsEmailBeingSent(!isEmailBeingSent);
      const dataToSend = { formData };

      const response = await axios.post(
        "https://jaaigroup.com/api/sendEmail.php",
        JSON.stringify(dataToSend),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_SEND_EMAIL_KEY}`,
          },
        }
      );

      // Check the response status
      if (response.status === 200) {
        const data = response.data;

        setTimeout(() => {
          setIdle(idleTime);
          clearInputs();
          setMessageSent(!messageSent);
          setIsEmailBeingSent(false);

          const currentTime = new Date().getTime();
          storage.setItem("lastMessageSent", currentTime);
        }, 1000);
        // console.log(data);
        // Process and use the data here, e.g., update the UI
      } else {
        setIsEmailBeingSent(false);
        // console.error("Request failed with status:", response.status);
        // Handle errors, e.g., display an error message to the user
      }
    } catch (error) {
      setIsEmailBeingSent(false);
      //   console.error("Error:", error);
      // Handle errors, e.g., display an error message to the user
    }
  };

  //sending message
  const sendMessage = async () => {
    const isMessageReady = await verifyInputs();

    if (isMessageReady) {
      const formData = {
        name: name,
        email: email,
        number: number,
        reason: reason,
        message: message,
      };

      sendMessageFunction(formData);
    }
  };

  //handler to check if there is message already sent and time

  useEffect(() => {
    if (storage.getItem("lastMessageSent")) {
      if (!messageSent) {
        setMessageSent(!messageSent);
      }
    } else {
      setMessageSent(false);
    }

    if (messageSent) {
      const getDifference = () => {
        const lastMessageSent = storage.getItem("lastMessageSent");
        const currentTime = new Date().getTime();
        const difference = currentTime - lastMessageSent;
        const resultInMinutes = Math.round(difference / 60000);
        const resultDifference = idleTime - resultInMinutes;

        if (resultDifference < 1) {
          storage.removeItem("lastMessageSent");
          clearInterval(differenceInterval);
          clearInputs();
          setMessageSent(false);
          setIdle(idleTime);
        } else {
          setIdle(resultDifference);
        }
      };

      getDifference();

      const differenceInterval = setInterval(() => {
        getDifference();
      }, [10000]);
    }

    //eslint-disable-next-line
  }, [messageSent]);

  //navigation thing from contact page (automatic reason selection)
  useEffect(() => {
    const { id } = params;

    if (data?.length > 0) {
      if (id) {
        console.log(`we got id of ${id}`);

        if (id === "consultation") {
          if (reduxLang === "lv") {
            setReason(reasonSelectionLatvian[0]?.name);
          } else if (reduxLang === "eng") {
            setReason(reasonSelectionEnglish[0]?.name);
          }
        } else if (id === "review") {
          if (reduxLang === "lv") {
            setReason(reasonSelectionLatvian[3]?.name);
          } else if (reduxLang === "eng") {
            setReason(reasonSelectionEnglish[3]?.name);
          }
        } else if (id === "hosting") {
          if (reduxLang === "lv") {
            setReason(reasonSelectionLatvian[2]?.name);
          } else if (reduxLang === "eng") {
            setReason(reasonSelectionEnglish[2]?.name);
          }
        } else if (id === "service") {
          if (reduxLang === "lv") {
            setReason(reasonSelectionLatvian[1]?.name);
          } else if (reduxLang === "eng") {
            setReason(reasonSelectionEnglish[1]?.name);
          }
        }
      }
    }

    //eslint-disable-next-line
  }, [params, data]);

  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>JAAIGROUP: Sazinies ar Mums</title>
        <link rel="canonical" href="http://jaaigroup.com/" />
        <meta
          property="og:title"
          content="JAAIGROUP: Sazinies ar Mums un Kontaktinformācija"
        />
        <meta name="author" content="JAAIGROUP" />
        <meta
          name="description"
          content="Sazinieties ar JAAIGROUP, lai uzzinātu vairāk par mūsu pakalpojumiem un risinājumiem. Mēs esam šeit, lai palīdzētu jums realizēt digitālos mērķus un piedāvāt efektīvus risinājumus jūsu biznesa izaugsmei. Atrisināsim jūsu jautājumus un sūtīsim detalizētu informāciju."
        />
        <meta
          property="og:description"
          content="Sazinieties ar JAAIGROUP, lai uzzinātu vairāk par mūsu pakalpojumiem un risinājumiem. Mēs esam šeit, lai palīdzētu jums realizēt digitālos mērķus un piedāvāt efektīvus risinājumus jūsu biznesa izaugsmei. Atrisināsim jūsu jautājumus un sūtīsim detalizētu informāciju."
        />
        <meta property="og:locale" content="lv_LV" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="http://jaaigroup.com/" />
        <meta
          property="og:site_name"
          content="JAAIGROUP: Sazinies ar Mums un Kontaktinformācija"
        />
        <meta
          name="twitter:description"
          content="Sazinieties ar mums, lai uzzinātu vairāk par mūsu piedāvātajiem risinājumiem un pakalpojumiem. Mēs esam gatavi atbildēt uz jūsu jautājumiem un sniegt detalizētu informāciju."
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content="JAAIGROUP: Sazinies ar Mums un Kontaktinformācija"
        />
        <meta
          name="keywords"
          content="Sazinies ar mums, Kontakti, Kontaktinformācija, JAAIGROUP, digitālie risinājumi, biznesa izaugsme, efektīvi risinājumi, jautājumi, detalizēta informācija, uzņēmuma kontaktpersonas, saziņas veidi, uzņēmuma adrese, telefons, e-pasts"
        />
      </Helmet>

      <AnimatedComponent>
        <PageHeading data={pageData} />

        <div className="contact-data">
          <div className="contact-left">
            <h3>{data[1]?.text[0]?.text}</h3>

            <div className="contact-left-options">
              <div className="contact-left-btns">
                <a href="https://wa.me/37125397828">
                  <div className="contacts-left-btn">
                    <Whatsapp className="icon" />
                  </div>
                  <p>WhatsApp</p>
                </a>

                <a href="mailto:info@jaaigroup.com">
                  <div className="contacts-left-btn">
                    <Mail className="icon" />
                  </div>
                  <p>info@jaaigroup.com</p>
                </a>

                <a href="tel:+37125397828">
                  <div className="contacts-left-btn">
                    <Call className="icon" />
                  </div>
                  <p>{data[1]?.text[1]?.text} +371 25 397 828</p>
                </a>

                <Link to="/contact">
                  <div className="contacts-left-btn">
                    <PenFill className="icon" />
                  </div>
                  <p>{data[1]?.text[2]?.text}</p>
                </Link>
              </div>
            </div>
          </div>

          <div className="contact-right">
            {messageSent ? (
              <>
                <ContactAlert>
                  <div className="checkmark-box">
                    <Checkmark className="icon" />
                  </div>
                  <h3>{data[0]?.text[0]?.text}</h3>
                  <p>
                    {data[0]?.text[1]?.text} {idle} {data[0]?.text[2]?.text}.
                  </p>
                </ContactAlert>
              </>
            ) : (
              <>
                <h3>{data[2]?.text[0]?.text}</h3>

                <div className="input-container">
                  {nameAlert && <p className="alert-text">{nameAlert}</p>}
                  <input
                    type="text"
                    disabled={isEmailBeingSent}
                    placeholder={data[2]?.text[1]?.text}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>

                <div className="input-container">
                  {emailAlert && <p className="alert-text">{emailAlert}</p>}
                  <input
                    type="email"
                    disabled={isEmailBeingSent}
                    placeholder={data[2]?.text[2]?.text}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div className="input-container">
                  <input
                    type="number"
                    disabled={isEmailBeingSent}
                    placeholder={data[2]?.text[3]?.text}
                    value={number}
                    min={0}
                    onChange={(e) => {
                      e.target.value.length < 12 && setNumber(e.target.value);
                    }}
                  />
                </div>

                <div className="input-container">
                  <p className="contact-reason-heading">
                    {data[2]?.text[4]?.text}
                  </p>
                  {isEmailBeingSent ? (
                    <>
                      <div
                        className="contact-reason-container"
                        style={{ background: "#bebebe" }}
                      >
                        <p className="contact-reason">{reason}</p>
                        {showReasons ? (
                          <UpArrow className="icon" />
                        ) : (
                          <DownArrow className="icon" />
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="contact-reason-container"
                        onClick={() =>
                          setShowReasons((showReasons) => !showReasons)
                        }
                      >
                        <p className="contact-reason">{reason}</p>
                        {showReasons ? (
                          <UpArrow className="icon" />
                        ) : (
                          <DownArrow className="icon" />
                        )}
                      </div>
                    </>
                  )}

                  {showReasons && (
                    <div className="contact-reason-expanded">
                      {reasonSelection?.map((reason) => (
                        <div
                          key={reason?.id}
                          className="contact-reason-expanded-reason"
                          onClick={() => {
                            setReason(reason?.name);
                            setShowReasons((showReasons) => !showReasons);
                          }}
                        >
                          <p>{reason?.name}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                <div className="input-container">
                  {messageAlert && <p className="alert-text">{messageAlert}</p>}
                  <textarea
                    disabled={isEmailBeingSent}
                    placeholder={data[2]?.text[5]?.text}
                    maxLength="500"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </div>

                <div className="input-container">
                  <p className="contact-letter-count">
                    {data[2]?.text[6]?.text} {letterCount}
                  </p>
                </div>

                <div className="input-container">
                  {isEmailBeingSent ? (
                    <>
                      <div className="contact-btn contact-btn-sending">
                        <p>{data[2]?.text[7]?.text}</p>
                      </div>
                    </>
                  ) : (
                    <div className="contact-btn" onClick={() => sendMessage()}>
                      <p>{data[2]?.text[8]?.text}</p>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </AnimatedComponent>
    </Wrapper>
  );
};

const ContactAlert = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .checkmark-box {
    background: #376058;
    padding: 12px;
    display: inline-block;
    border-radius: 50%;
    margin-bottom: 20px;

    .icon {
      width: 24px;
      height: 24px;
      color: white;
    }
  }

  p {
    text-align: center;
    font-size: 0.9rem;
  }

  h3 {
    text-align: center;
    font-size: 1.2rem;
  }
`;

const Wrapper = styled.div`
  .contact-data {
    max-width: 1200px;
    margin: 80px auto;
    padding: 0 40px;
    display: flex;
    gap: 20px;
    justify-content: space-between;

    @media only screen and (max-width: 660px) {
      flex-direction: column;
      margin: 40px 0;
      padding: 0 20px;
    }

    .contact-right {
      .contact-reason-expanded {
        .contact-reason-expanded-reason {
          background: #dedede;
          padding: 12px 20px;
          transition: 0.4s ease-in-out;
          cursor: pointer;

          p {
            font-size: 0.8rem;
          }
        }

        .contact-reason-expanded-reason:hover {
          background: #cdcdcd;
        }
      }

      .contact-reason-container {
        padding: 12px 20px;
        background: #dedede;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0 0 0;
        transition: 0.4s ease-in-out;
        cursor: pointer;

        .contact-reason {
          color: #121315;
          font-size: 0.8rem;
          font-weight: bold;
          color: #838383;
        }

        .icon {
          width: 18px;
          height: 18px;
          color: #838383;
        }
      }

      .contact-reason-container:hover {
        background: #cdcdcd;
      }

      .contact-btn {
        background: #376058;
        color: white;
        text-decoration: none;
        display: inline-block;
        padding: 12px 20px;
        border-radius: 10px;
        transition: 0.4s ease-in-out;
        cursor: pointer;
        float: right;
        margin: 15px 0 0 0;

        p {
          font-size: 0.8rem;
        }
      }

      .contact-btn:hover {
        box-shadow: inset 10rem 0 0 0 #121315;
      }

      .contact-btn-sending {
        background: #bebebe;
        color: black;
        cursor: progress;
      }

      .contact-btn-sending:hover {
        color: black;
        box-shadow: none !important;
      }

      .contact-letter-count {
        font-size: 0.8rem;
        color: #121315;
        display: flex;
        justify-content: flex-end;
      }

      .input-container {
        width: 100%;
        max-width: 500px;
        margin-bottom: 10px;

        input:disabled,
        textarea:disabled {
          background: #bebebe;
        }

        .alert-text {
          padding: 12px 20px;
          font-size: 0.8rem;
          background: #ff9481;
          color: #121315;
        }

        @media only screen and (max-width: 660px) {
          max-width: 100% !important;
        }

        .contact-reason-heading {
          color: #616161;
          font-weight: 400;
          font-size: 0.8rem;
          margin: 20px 0 0 0;
        }

        input {
          width: 100%;
          border: none;
          background: #dedede;
          padding: 12px 20px;
          font-size: 0.8rem;
          outline: none;
          color: #121315;
          transition: 0.4s ease-in-out;
        }

        input::placeholder {
          font-weight: 600;
          color: #838383;
        }

        input:focus {
          background: #cdcdcd;
        }

        textarea {
          min-height: 200px;
          padding: 12px 20px;
          border-radius: 0px;
          outline: none;
          border: none;
          resize: vertical;
          color: #121315;
          font-size: 0.8rem;
          width: 100% !important;
          background: #dedede;
          transition: 0.4s ease-in-out;
          font-family: "Inter", sans-serif;
          font-weight: 400;
        }

        textarea:focus {
          background: #cdcdcd;
        }

        textarea::placeholder {
          font-size: 0.8rem;
          font-family: "Inter", sans-serif;
          font-weight: bold;
        }
      }
    }

    .contact-left {
      .contact-left-btns {
        display: flex;
        flex-direction: column;

        a {
          color: #121315;
          display: flex;
          gap: 10px;
          align-items: center;
          margin-bottom: 10px;
          text-decoration: none;

          .contacts-left-btn {
            background: #121315;
            padding: 6px;
            border-radius: 10px;
            transition: 0.4s ease-in-out;

            .icon {
              width: 24px;
              height: 24px;
              color: white;
            }
          }

          p {
            font-size: 0.9rem;
            color: #121315;
          }
        }

        a:hover {
          font-weight: bold;

          .contacts-left-btn {
            box-shadow: inset 3rem 0 0 0 #376058;
          }
        }
      }
    }

    .contact-left,
    .contact-right {
      width: 50%;

      h3 {
        font-size: 1.2rem;
        color: #121315;
        margin: 0 0 20px 0;
      }

      @media only screen and (max-width: 660px) {
        width: 100%;
      }
    }
  }
`;

export default Contact;
