import React from "react";
import styled from 'styled-components'

const PageHeading = ({data}) => {
  return <Wrapper>
    
    <img src={data?.img} alt='page img' />
    
    <div className='page-heading'>
        <h4>{data?.name}</h4>
        <p>{data?.desc}</p>
    </div>
</Wrapper>;
};

const Wrapper = styled.div`
max-width: 1200px;
margin: 0 auto 30px;
position: relative;

img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    filter: brightness(.8);

    @media only screen and (max-width: 660px) { 
        height: 250px;
    }
}

.page-heading {
    position: absolute;
    background: white;
    bottom: 0;
    left: 0;
    padding: 15px 20px;
    max-width: 450px;
    
    h4 {
        font-size: 1.2rem;
        margin-bottom: 10px;
        color: #121315;
    }

    p {
        font-size: .8rem;
        color: #4D4D4D;
        line-height: 1.3;
    }
}
`

export default PageHeading;
