import React, { useState, useEffect } from "react";
import styled from "styled-components";

//icons
import { Call } from "@styled-icons/material/Call";
import { Email } from "@styled-icons/material/Email";
import { ArrowIosDownward } from "@styled-icons/evaicons-solid/ArrowIosDownward";
import { KeyboardArrowRight } from "@styled-icons/material/KeyboardArrowRight";

//router
import { Link, NavLink, useNavigate } from "react-router-dom";

//mobile
import Hamburger from "hamburger-react";

//redux
import { useDispatch, useSelector } from "react-redux";
import { selectLanguage, setLanguage } from "../redux/variables";

//components
import AnimatedComponent from "./AnimatedComponent";

const Header = () => {
  //redux
  const dispatch = useDispatch();
  const reduxLang = useSelector(selectLanguage);

  //redux -- languages
  const navBarLanguages = document.querySelectorAll(".lang-selection");

  const updateLanguage = (lang) => {
    for (let i = 0; i < navBarLanguages?.length; i++) {
      navBarLanguages[i].classList.remove("lang-select-selected");
    }

    if (lang === "lv") {
      navBarLanguages[0].classList.add("lang-select-selected");
      window.localStorage.setItem("lang", "lv");
      dispatch(setLanguage("lv"));
    } else if (lang === "eng") {
      navBarLanguages[1].classList.add("lang-select-selected");
      window.localStorage.setItem("lang", "eng");
      dispatch(setLanguage("eng"));
    }
  };

  //if user goes to .../lv or .../en
  const navigate = useNavigate();

  //onload check languages
  useEffect(() => {
    const savedLanguage = window.localStorage.getItem("lang");
    const navBarLanguagesOnload = document.querySelectorAll(".lang-selection");

    if (!savedLanguage) {
      window.localStorage.setItem("lang", "lv");
      dispatch(setLanguage("lv"));
    } else {
      dispatch(setLanguage(savedLanguage));
      if (savedLanguage === "lv") {
        navBarLanguagesOnload[0].classList.add("lang-select-selected");
      } else if (savedLanguage === "eng") {
        navBarLanguagesOnload[1].classList.add("lang-select-selected");
      }
    }

    const path = window.location.pathname;

    if (path === "/lv") {
      window.localStorage.setItem("lang", "lv");
      dispatch(setLanguage("lv"));
      navigate("/");
    } else if (path === "/eng") {
      window.localStorage.setItem("lang", "eng");
      dispatch(setLanguage("eng"));
      navigate("/");
    }
    //eslint-disable-next-line
  }, []);

  const [menu, setMenu] = useState([]);

  const menuLatvian = [
    {
      id: 0,
      name: "menu",
      menu: [
        { id: 0, name: "Sākums", link: "/" },
        { id: 1, name: "Servisi", link: "/services" },
        { id: 2, name: "Cenas", link: "/pricing" },
        { id: 3, name: "Hostings", link: "/hosting" },
        { id: 4, name: "Par mums", link: "/about" },
        { id: 5, name: "Sazināties", link: "/contact" },
      ],
    },
    {
      id: 1,
      name: "services",
      services: [
        { id: 0, name: "Mājas lapu izstrāde", link: "/services/web" },
        {
          id: 1,
          name: "Internetveikalu izstrāde",
          link: "/services/ecommerce",
        },
        { id: 2, name: "SEO optimizācija", link: "/services/seo" },
        { id: 3, name: "Grafiskais dizains", link: "/services/design" },
        { id: 4, name: "Satura vadības sistēma", link: "/services/cms" },
        { id: 5, name: "Mobilo aplikāciju izstrāde", link: "/services/mobile" },
        {
          id: 6,
          name: "Personalizētas WEB sistēmas",
          link: "/services/web-systems",
        },
      ],
    },
  ];

  const menuEnglish = [
    {
      id: 0,
      name: "menu",
      menu: [
        { id: 0, name: "Home", link: "/" },
        { id: 1, name: "Services", link: "/services" },
        { id: 2, name: "Pricing", link: "/pricing" },
        { id: 3, name: "Hosting", link: "/hosting" },
        { id: 4, name: "About us", link: "/about" },
        { id: 5, name: "Contact", link: "/contact" },
      ],
    },
    {
      id: 1,
      name: "services",
      services: [
        { id: 0, name: "Website development", link: "/services/web" },
        { id: 1, name: "Ecommerce development", link: "/services/ecommerce" },
        { id: 2, name: "SEO optimization", link: "/services/seo" },
        { id: 3, name: "Graphic design", link: "/services/design" },
        { id: 4, name: "CMS", link: "/services/cms" },
        { id: 5, name: "Mobile app development", link: "/services/mobile" },
        { id: 6, name: "Custom web systems", link: "/services/web-systems" },
      ],
    },
  ];

  useEffect(() => {
    if (reduxLang) {
      if (reduxLang === "lv") {
        setMenu(menuLatvian);
      } else if (reduxLang === "eng") {
        setMenu(menuEnglish);
      }
    }
    //eslint-disable-next-line
  }, [reduxLang]);

  //mobile menu
  const [isOpen, setOpen] = useState(false);

  //Info bar
  const [isScrollingUp, setIsScrollingUp] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 600);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const scrollingUp = prevScrollPos > currentScrollPos;

      if (Math.abs(currentScrollPos - prevScrollPos) > 50) {
        setIsScrollingUp(scrollingUp);
        setPrevScrollPos(currentScrollPos);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    //eslint-disable-next-line
  }, [prevScrollPos]);

  // Only apply the className function on mobile devices.
  const InfoBarShowHideClass = isMobile
    ? `scroll-toggle ${isScrollingUp ? "visible" : "hidden"}`
    : "";

  return (
    <Wrapper>
      <AnimatedComponent>
        <InfoBar className={InfoBarShowHideClass}>
          <InfoBarContent>
            <a className="info-data" href="tel:+371 25 397 828">
              <Call className="icon" />
              <p>+371 25 397 828</p>
            </a>

            <a
              className="info-data info-email"
              href="mailto:info@jaaigroup.com"
            >
              <Email className="icon" />
              <p>info@jaaigroup.com</p>
            </a>

            <div className="lang-select">
              <p
                className="lang-selection"
                onClick={() => updateLanguage("lv")}
              >
                LV
              </p>
              <p
                className="lang-selection"
                onClick={() => updateLanguage("eng")}
              >
                ENG
              </p>
            </div>
          </InfoBarContent>
        </InfoBar>

        <NavBar>
          <Link
            to={menu[0]?.menu[0]?.link}
            onClick={() => window.scrollTo(0, 0)}
          >
            <img src="../img/logo.png" alt="jaaigroup logo" />
          </Link>

          <nav className="nav-desktop">
            <NavLink to={menu[0]?.menu[0]?.link}>
              <p>{menu[0]?.menu[0]?.name}</p>
            </NavLink>

            <NavLink
              className="nav-dekstop-services"
              to={menu[0]?.menu[1]?.link}
            >
              <p>{menu[0]?.menu[1]?.name}</p>
              <ArrowIosDownward className="icon icon-services" />

              <div className="nav-dekstop-services-expanded">
                {menu[1]?.services?.map((service) => (
                  <Link key={service?.id} to={service?.link}>
                    {service?.name}
                  </Link>
                ))}
              </div>
            </NavLink>

            <NavLink
              to={menu[0]?.menu[2]?.link}
              onClick={() => window.scrollTo(0, 0)}
            >
              <p>{menu[0]?.menu[2]?.name}</p>
            </NavLink>

            <NavLink
              to={menu[0]?.menu[3]?.link}
              onClick={() => window.scrollTo(0, 0)}
            >
              <p>{menu[0]?.menu[3]?.name}</p>
            </NavLink>

            <NavLink
              to={menu[0]?.menu[4]?.link}
              onClick={() => window.scrollTo(0, 0)}
            >
              <p>{menu[0]?.menu[4]?.name}</p>
            </NavLink>
          </nav>

          <Link
            to={menu[0]?.menu[5]?.link}
            onClick={() => window.scrollTo(0, 0)}
            className="contact-btn"
          >
            <p>{menu[0]?.menu[5]?.name}</p>
          </Link>

          <div className="mobile-hamburger">
            <Hamburger toggled={isOpen} toggle={setOpen} size={24} />
          </div>
        </NavBar>

        {isOpen && (
          <MobileMenu>
            {menu[0]?.menu?.map((nav) => (
              <Link
                key={nav?.id}
                to={nav?.link}
                onClick={() => {
                  window.scrollTo(0, 0);
                  setOpen((isOpen) => !isOpen);
                }}
              >
                <div className="mobile-menu">
                  <p>{nav?.name}</p>
                  <KeyboardArrowRight className="icon" />
                </div>
              </Link>
            ))}
          </MobileMenu>
        )}
      </AnimatedComponent>
    </Wrapper>
  );
};

const NavBar = styled.div`
  max-width: 1200px;
  padding: 27px 15px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 870px) {
    padding: 17px 15px;
  }

  img {
    width: 30%;
    min-width: 120px;
    max-width: 140px;
  }

  .mobile-hamburger {
    display: none;

    @media only screen and (max-width: 870px) {
      display: block !important;
    }
  }

  .nav-desktop,
  .contact-btn {
    @media only screen and (max-width: 870px) {
      display: none !important;
    }
  }

  .nav-desktop {
    display: flex;
    align-items: center;
    gap: 1.4rem;

    .nav-dekstop-services-expanded {
      font-weight: 400 !important;
    }

    .nav-dekstop-services {
      .icon-services {
        transition: 0.2s ease-in-out;
      }
    }

    .nav-dekstop-services:hover {
      .icon-services {
        transform: rotate(180deg);
      }

      .nav-dekstop-services-expanded {
        display: flex;
      }
    }

    a {
      text-decoration: none;
      color: #121315;
      font-size: 1rem;
      display: flex;
      gap: 5px;
      padding: 10px;
      border-radius: 10px;
      transition: 0.2s ease-in-out;
      position: relative;

      .nav-dekstop-services-expanded {
        position: absolute;
        top: 40px;
        left: 10px;
        background: white;
        padding: 10px;
        display: flex;
        min-width: 400px;
        flex-wrap: wrap;
        display: none;

        a {
          font-size: 0.8rem;
          color: #121315;
          transition: 0.4s ease-in-out;
        }

        a:hover {
          opacity: 0.6;
        }
      }
    }

    p {
      font-size: 1rem;
    }

    .active {
      font-weight: 600;
    }

    .icon {
      width: 18px;
      height: 18px;
    }
  }

  .contact-btn {
    background: #376058;
    color: white;
    text-decoration: none;
    display: inline-block;
    padding: 15px 20px;
    border-radius: 10px;
    transition: 0.4s ease-in-out;

    p {
      font-size: 0.9rem;
    }
  }

  .contact-btn:hover {
    background: #1d473f;
  }
`;

const InfoBarContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: end;
  padding: 15px 20px;
  gap: 45px;

  @media only screen and (max-width: 600px) {
    justify-content: space-between;
  }

  a,
  p {
    transition: 0.4s ease-in-out;
  }

  a:hover,
  p:hover {
    opacity: 0.6;
  }

  .lang-select {
    display: flex;
    gap: 10px;
    align-items: center;

    .lang-select-selected {
      font-weight: 600;
    }

    p {
      cursor: pointer;
      font-size: 0.8rem;
    }
  }

  .info-email {
    @media only screen and (max-width: 600px) {
      display: none !important;
    }
  }

  .info-data {
    display: flex;
    gap: 10px;
    align-items: center;
    text-decoration: none;
    color: white;

    p {
      font-size: 0.8rem;
    }
  }

  .icon {
    width: 24px;
    height: 24px;
  }
`;

const InfoBar = styled.div`
  background: #376058;
  width: 100%;
  color: white;
`;

const MobileMenu = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 15px 20px;

  a {
    color: #121315;
    font-size: 1rem;
    text-decoration: none;
  }

  .mobile-menu {
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 10px;
    cursor: pointer;
    transition: 0.2s ease-in-out;

    p {
      color: #121315;
      font-size: 1rem;
    }

    .icon {
      width: 24px;
      height: 24px;
      color: #121315;
    }
  }

  a:hover {
    color: white;

    .mobile-menu {
      background: #376058;
    }

    .icon,
    p {
      color: white;
    }
  }
`;

const Wrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 100;
  background: rgb(255 255 255 / 90%);
  backdrop-filter: blur(6px);

  @media only screen and (max-width: 870px) {
    background: rgb(255 255 255 / 98%);
  }

  .scroll-toggle {
    height: 55px;
    overflow: hidden;
    transition: height 0.3s ease-in-out;
    transform: translateY(0);
  }

  .visible {
    transform: translateY(0);
  }

  .hidden {
    height: 0;
    overflow: hidden;
  }
`;

export default Header;
